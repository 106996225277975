import gql from 'graphql-tag';

export const ALL_PIZZAS = gql`
    query allPizzas {
        allPizzas {
            totalCount
            edges {
                node {
                    id
                    name {
                        en
                        is
                    }
                    description {
                        en
                        is
                    }
                    slug
                    imageUrl
                    imagePublicId
                    priceSmall
                    priceMedium
                    priceLarge
                    canBeDivided
                    toppings {
                        amount
                        topping {
                            id
                            name {
                                en
                                is
                            }
                            priceSmall
                            priceLarge
                            priceMedium
                            maxAmount
                        }
                    }
                }
            }
        }
    }
`;

export const CASTELLO_SETTINGS = gql`
    query getCastelloSettings {
        getCastelloSettings {
            minAmountDelivery
            basePriceSmallPizza
            basePriceMediumPizza
            basePriceLargePizza
            priceDelivery
            baseToppings
            frontImageUrl
            frontImagePublicId
            frontTitle {
                en
                is
            }
            frontSubtitle {
                en
                is
            }
        }
    }
`;

export const ALL_TOPPINGS = gql`
    query allToppings {
        allToppings {
            edges {
                node {
                    category {
                        id
                        name {
                            en
                            is
                        }
                    }
                    toppings {
                        id
                        name {
                            en
                            is
                        }
                        priceSmall
                        priceMedium
                        priceLarge
                        maxAmount
                    }
                }
            }
        }
    }
`;

export const ALL_MENU_CATEGORIES = gql`
    query allMenuCategories {
        allMenuCategories {
            totalCount
            edges {
                node {
                    name {
                        en
                        is
                    }
                    slug
                    id
                    catalogueOnly
                }
            }
        }
    }
`;

export const ALL_MENU_ITEMS = gql`
    query allMenuItems($category: ID) {
        allMenuItems(category: $category) {
            totalCount
            edges {
                node {
                    category {
                        name {
                            en
                            is
                        }
                        id
                        slug
                    }
                    items {
                        id
                        slug
                        name {
                            en
                            is
                        }
                        price
                        description {
                            en
                            is
                        }
                        imageUrl
                        imagePublicId
                        category {
                            id
                            name {
                                en
                                is
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ACTIVE_OFFERS = gql`
    query activeOffers {
        activeOffers {
            totalCount
            edges {
                node {
                    name {
                        en
                        is
                    }
                    description {
                        en
                        is
                    }
                    availableToOrder
                    offerValidDays
                    offerValidHours {
                        from
                        to
                    }
                    details {
                        size
                        isMenuPizza
                        allowedItems
                        offerType
                        toppingAmount
                    }
                    slug
                    id
                    isValid
                    isDelivery
                    isPickup
                    price
                    imageUrl
                    imagePublicId
                    category
                }
            }
        }
    }
`;

export const ALL_DISCOUNTS = gql`
    query activeDiscounts {
        activeDiscounts {
            totalCount
            edges {
                node {
                    name {
                        en
                        is
                    }
                    description {
                        en
                        is
                    }
                    isPickup
                    isDelivery
                    isAllItems
                    discountPercentage
                    discountValidDays
                    active
                    allowedItems
                    discountType
                    createdBy {
                        name
                    }
                }
            }
        }
    }
`;

export const ALL_BRANCHES = gql`
    query allBranches {
        allBranches {
            totalCount
            edges {
                node {
                    id
                    name
                    address {
                        street
                        city
                        postalCode
                    }
                    phone
                    homeDeliveryPostalCodes
                    estimatedPickupTime
                    estimatedDeliveryTime
                    minutesBeforeOnlineSalesClose
                    openingHours {
                        mon {
                            from
                            to
                            isClosed
                        }
                        tue {
                            from
                            to
                            isClosed
                        }
                        wed {
                            from
                            to
                            isClosed
                        }
                        thu {
                            from
                            to
                            isClosed
                        }
                        fri {
                            from
                            to
                            isClosed
                        }
                        sat {
                            from
                            to
                            isClosed
                        }
                        sun {
                            from
                            to
                            isClosed
                        }
                    }
                }
            }
        }
    }
`;

export const MY_ORDER = gql`
    query myOrder($id: ID!, $netgiroTransactionId: String) {
        myOrder(id: $id, netgiroTransactionId: $netgiroTransactionId) {
            user {
                name
                phone
            }
            orderNumber
            branch {
                name
                estimatedPickupTime
                estimatedDeliveryTime
                address {
                    street
                    city
                    postalCode
                }
            }
            status
            message
            deliveryMethod
            deliveryAddress {
                postalCode
                address
                city
                message
            }
            totalPrice
            totalVat
            totalDeliveryPrice
            cart {
                name {
                    en
                    is
                }
                payload1
                payload2
                size
                type
                price
                isDivided
                toppingsSelectionSide1 {
                    topping {
                        en
                        is
                    }
                    amount
                    toppingId
                }
                toppingsSelectionSide2 {
                    topping {
                        en
                        is
                    }
                    amount
                    toppingId
                }
                details {
                    type
                    name {
                        en
                        is
                    }
                    isDivided
                    size
                    toppings {
                        topping {
                            en
                            is
                        }
                        amount
                        toppingId
                    }
                }
            }
        }
    }
`;
