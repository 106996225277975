import { CHANGE_LANGUAGE } from '../constants';
import { changeLanguage } from '../utils/lang.utils'


const initialState = 'is';

export default function (state = initialState, action) {
    const { type, lang } = action
    switch (type) {
        case CHANGE_LANGUAGE: {
            return changeLanguage(lang);
        }
        default: {
            return state;
        }
    }
}