import React, { useState, useEffect } from 'react';

// UI
import { DropDown, RadioGroup, Section, CheckGroup, HotBar, PizzaHeader, StickySection } from '../components/UI';
import { toast } from 'react-toastify';

// Redux
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/actions/cart';

// Data
import { usePizzas, useToppings, useDiscounts } from '../hooks';
import { formatISK } from '../services/prices';

// Assets
import ToppingsIcon from '../assets/icons/toppings';
import DivisionIcon from '../assets/icons/division';
import { Button } from '../components/UI';

// Util
import { LargeImage } from '../services/cloudinary';

// i18n
import transcript from '../i18n/PizzaPage';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

// Constants
const sizes = [
    { label: '9"', value: 'Small' },
    { label: '12"', value: 'Medium' },
    { label: '15"', value: 'Large' },
];

const PizzaPage = (props) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang);

    // URL PARAMS
    let [searchParams] = useSearchParams();
    let searchDivide = searchParams.get('divide');
    let searchToppings = searchParams.get('toppings');

    // --------------------
    // Data
    // --------------------

    // Size selector
    const [size, setSize] = useState('Large');

    // Topping selections
    const [customToppings, setCustomToppings] = useState(!!searchToppings || true);
    const [toppingsSelectionSide1, setToppingSelectionSide1] = useState([]);
    const [toppingsSelectionSide2, setToppingSelectionSide2] = useState([]);

    // Pizza division
    const [dividePizza, setDividePizza] = useState(searchDivide ? 1 : false);

    // Data
    const { pizzas } = usePizzas();
    const { toppings } = useToppings();
    const { discounts } = useDiscounts();

    // Current pizza
    const { slug: pizzaSlug } = useParams();

    const [pizza, setPizza] = useState(null);
    const [pizza2, setPizza2] = useState(null);

    // Cache sides
    const [p1, setP1] = useState('');
    const [p2, setP2] = useState('');

    // TODO: Init price from pizza.price[size]
    const [price, setPrice] = useState(0);
    const [bestPrice, setBestPrice] = useState(0);
    const [toppingsPrice, setToppingsPrice] = useState(0);
    const [bestToppingsPrice, setBestToppingsPrice] = useState(0);

    const flatToppings = toppings.map((t) => t.toppings).flat();
    const selectedToppings = (dividePizza === 2 ? toppingsSelectionSide2 : toppingsSelectionSide1)
        ?.map((t) => flatToppings.find((topping) => topping.id === t.id))
        .filter((t) => !!t);

    const navigate = useNavigate();

    // --------------------
    // Effects
    // --------------------
    useEffect(() => {
        let pizzaSlugs = pizzaSlug.split('+');

        if (!pizza && pizzas) {
            setPizza(pizzas.find((item) => item.slug === pizzaSlugs[0]));
        }

        if (pizza && searchDivide && !pizza2) {
            setPizza2(pizza);
        }

        // Toggle divide and set pizza2 if
        if (pizzaSlugs.length > 1 && dividePizza === false && pizzas && !pizza2) {
            let p = pizzas.find((item) => item.slug === pizzaSlugs[1]);
            setPizza2(pizzas.find((item) => item.slug === pizzaSlugs[1]));
            if (!!p) {
                setDividePizza(1);
            }
        }
    }, [pizzas, pizza]);

    // Fill toppings with selected pizzas toppings
    useEffect(() => {
        if (pizza && p1 !== pizza?.slug) {
            const defaultToppings = pizza?.toppings.map((topping) => ({
                id: topping.topping.id,
                amount: topping.amount,
            }));
            setToppingSelectionSide1(defaultToppings);
            setP1(pizza?.slug);
            if (!pizza2) {
                setToppingSelectionSide2(defaultToppings);
            }
        }

        if (pizza2 && p2 !== pizza2?.slug) {
            setP2(pizza2?.slug);
            const defaultToppingsSide2 = pizza2?.toppings.map((topping) => ({
                id: topping.topping.id,
                amount: topping.amount,
            }));
            setToppingSelectionSide2(defaultToppingsSide2);
        }
    }, [pizza, pizza2]);

    // Handle pizza price updating
    useEffect(() => {
        if (pizza) {
            const baseToppings = pizza.toppings.map((topping) => topping.topping);
            const baseToppings2 = pizza2?.toppings?.map((topping) => topping.topping);

            let tempPrice = pizza[`price${size}`];
            let extraToppingsPrice = 0;

            // Add other half in tempPrice if Menu Pizza
            if (dividePizza && pizza2) {
                tempPrice = tempPrice / 2 + pizza2[`price${size}`] / 2;
            }

            // Find additional toppings if one menu pizza
            let extraToppings = toppingsSelectionSide1?.filter(
                (topping) => !baseToppings.map((t) => t.id).includes(topping.id)
            );
            extraToppings = extraToppings?.map((topping) => ({
                ...flatToppings.find((t) => t.id === topping.id),
                amount: topping.amount,
            }));

            // Find addition amounts on base toppings
            let extraBaseAmount = toppingsSelectionSide1?.map((topping) => {
                let overage = pizza.toppings.find((bt) => bt.topping.id === topping.id && bt.amount < topping.amount);
                return overage
                    ? {
                          id: overage.topping.id,
                          baseAmount: overage.amount,
                          selectedAmount: topping.amount,
                          price: flatToppings.find((t) => t.id === topping.id)[`price${size}`],
                      }
                    : null;
            });

            if (extraToppings.length || toppingsSelectionSide2.length) {
                if (dividePizza) {
                    let extraToppings2 = [];
                    extraToppings2 = toppingsSelectionSide2?.filter(
                        (topping) => !(baseToppings2 || baseToppings).map((t) => t.id).includes(topping.id)
                    );
                    extraToppings2 = extraToppings2.map((topping) => ({
                        ...flatToppings.find((t) => t.id === topping.id),
                        amount: topping.amount,
                    }));

                    let extraBaseAmount2 = toppingsSelectionSide2?.map((topping) => {
                        let overage = pizza2?.toppings.find(
                            (bt) => bt.topping.id === topping.id && bt.amount < topping.amount
                        );
                        return overage
                            ? {
                                  id: overage.topping.id,
                                  baseAmount: overage.amount,
                                  selectedAmount: topping.amount,
                                  price: flatToppings.find((t) => t.id === topping.id)[`price${size}`],
                              }
                            : null;
                    });

                    extraToppings.map((topping) => {
                        extraToppingsPrice += (topping[`price${size}`] * topping.amount) / 2;
                        // return tempPrice += topping[`price${size}`] * topping.amount / 2;
                    });

                    extraToppings2.map((topping) => {
                        extraToppingsPrice += (topping[`price${size}`] * topping.amount) / 2;
                        // return tempPrice += topping[`price${size}`] * topping.amount / 2;
                    });

                    extraBaseAmount.map((extra) => {
                        if (extra) {
                            extraToppingsPrice += ((extra.selectedAmount - extra.baseAmount) * extra.price) / 2;
                        }
                    });

                    extraBaseAmount2.map((extra) => {
                        if (extra) {
                            extraToppingsPrice += ((extra.selectedAmount - extra.baseAmount) * extra.price) / 2;
                        }
                    });
                } else {
                    extraToppings.map((topping) => {
                        extraToppingsPrice += topping[`price${size}`] * topping.amount;
                        // return tempPrice += topping[`price${size}`] * topping.amount;
                    });
                    extraBaseAmount.map((extra) => {
                        if (extra) {
                            extraToppingsPrice += (extra.selectedAmount - extra.baseAmount) * extra.price;
                        }
                    });
                }
            }

            // TODO: handle discounts for each side
            // Check for absolute discounts

            // let tempBestPrice = tempPrice;
            // let tempBestToppingsPrice = extraToppingsPrice;
            // SKIL EKKI AFH ÉG REIKNAÐI TVISVAR
            // let active_discounts = discounts.filter((d) => d.discountType === 'Pizza' && d.isDelivery && d.isPickup);
            // active_discounts.map((discount) => {
            //     if (discount.allowedItems.includes(pizza.id) || discount.isAllItems) {
            //         console.log('IS THIS HAPPENING?');
            //         let discountedPrice = Math.ceil(tempPrice - tempPrice * (discount.discountPercentage / 100));
            //         let discountedToppingsPrice = Math.ceil(
            //             tempBestToppingsPrice - tempBestToppingsPrice * (discount.discountPercentage / 100)
            //         );
            //         tempBestPrice = Math.min(discountedPrice, tempPrice);
            //         tempBestToppingsPrice = Math.min(discountedToppingsPrice, tempBestToppingsPrice);
            //     }
            // });

            setPrice(tempPrice);
            setToppingsPrice(extraToppingsPrice);

            if (discounts.length) {
                let bestPickupPrice = price;
                let bestDeliveryPrice = price;
                let tempBestToppingsPrice = extraToppingsPrice;

                // Find all affecting discounts and calculate
                let active_discounts = discounts.filter((d) => d.discountType === 'Pizza');
                active_discounts.map((discount) => {
                    if (discount.allowedItems.includes(pizza) || discount.isAllItems) {
                        const { isDelivery, isPickup, discountPercentage } = discount;
                        let discountedPrice = Math.ceil(price - price * (discountPercentage / 100));
                        let discountedToppingsPrice = Math.ceil(
                            extraToppingsPrice - extraToppingsPrice * (discountPercentage / 100)
                        );

                        if (isDelivery && isPickup) {
                            // TODO: check if double counting the discount if for both delivery types
                            bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                            bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);
                            tempBestToppingsPrice = Math.min(discountedToppingsPrice, tempBestToppingsPrice);
                        } else if (isDelivery) {
                            bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                            tempBestToppingsPrice = Math.min(discountedToppingsPrice, tempBestToppingsPrice);
                        } else if (isPickup) {
                            bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);
                            tempBestToppingsPrice = Math.min(discountedToppingsPrice, tempBestToppingsPrice);
                        }
                    }
                });

                // Return the lowest price
                setBestPrice(Math.min(bestDeliveryPrice, bestPickupPrice));
                setBestToppingsPrice(tempBestToppingsPrice);
            } else {
                setBestPrice(tempPrice);
                setBestToppingsPrice(extraToppingsPrice);
            }
        }
    }, [size, toppingsSelectionSide1, toppingsSelectionSide2, dividePizza, pizza, pizza2, flatToppings]);

    // --------------------
    // Actions
    // --------------------
    const toggleToppings = () => {
        setCustomToppings(!customToppings);
    };

    const toggleDivision = () => {
        if (!dividePizza) {
            setDividePizza(1);
            setPizza2(pizza);
        } else {
            setDividePizza(0);
            navigate(`/pizza/${pizza.slug}`);
        }
    };

    const handlePizzaChange = (slug) => {
        let url;
        let pizzaObject = pizzas.find((p) => p.slug === slug);

        if (dividePizza) {
            if (dividePizza === 2) {
                setPizza2(pizzaObject);
                url = `${pizza.slug}+${slug}`;
            } else {
                setPizza(pizzaObject);
                url = `${slug}+${pizza2?.slug}`;
            }
        } else {
            url = pizzaObject.slug;
            setPizza(pizzaObject);
            setPizza2(false);
        }

        navigate(`/pizza/${url}`);
    };

    const selectTopping = (val) => {
        const setTopping = dividePizza === 2 ? setToppingSelectionSide2 : setToppingSelectionSide1;
        const currentSide = dividePizza === 2 ? toppingsSelectionSide2 : toppingsSelectionSide1;

        if (currentSide.map((value) => value.id).includes(val)) {
            setTopping(currentSide.filter((topping) => topping.id !== val));
        } else {
            setTopping([...currentSide, { id: val, amount: 1 }]);
        }
    };

    const selectToppingAmount = (val) => {
        const setTopping = dividePizza === 2 ? setToppingSelectionSide2 : setToppingSelectionSide1;
        const currentSide = dividePizza === 2 ? toppingsSelectionSide2 : toppingsSelectionSide1;

        const currentTopping = currentSide.find((topping) => topping.id === val);
        if (currentTopping) {
            setTopping([
                ...currentSide.filter((topping) => topping.id !== val),
                { id: val, amount: currentTopping.amount > 1 ? 1 : 2 },
            ]);
        } else {
            setTopping([...currentSide, { id: val, amount: 2 }]);
        }
    };

    // Ordering
    const handleAddToCart = () => {
        let product = {
            type: 'Pizza',
            name: `${pizza.name[lang]} ${pizza2 && pizza2.slug !== pizza.slug ? `// ${pizza2.name[lang]}` : ''}`,
            description: selectedToppings.map((topping) => topping.name[lang]).join(', '),
            size: size.toLowerCase(),
            id: pizza.id,
            id2: pizza2?.id,
            toppingsSelectionSide1,
            toppingsSelectionSide2,
            isDivided: dividePizza,
            price: price + toppingsPrice,
            baseToppings1: pizza.toppings,
            baseToppings2: pizza2?.toppings,
        };

        // TODO: handle discounts for each side
        product = applyPizzaDiscount(product);

        dispatch(addToCart(product));

        toast(transcript[lang].addedToCart, { type: 'success', position: 'bottom-right' });
        navigate(`/pizzas`);
    };

    const applyPizzaDiscount = (product) => {
        if (discounts.length) {
            let bestPickupPrice = price;
            let bestDeliveryPrice = price;

            let bestPickupToppingsPrice = toppingsPrice;
            let bestDeliveryToppingsPrice = toppingsPrice;

            // Find all affecting discounts and calculate
            let active_discounts = discounts.filter((d) => d.discountType === 'Pizza');
            active_discounts.map((discount) => {
                if (discount.allowedItems.includes(product.id) || discount.isAllItems) {
                    const { isDelivery, isPickup, discountPercentage } = discount;
                    let discountedPrice = Math.ceil(price - price * (discountPercentage / 100));
                    let discountedToppingsPrice = Math.ceil(toppingsPrice - toppingsPrice * (discountPercentage / 100));
                    if (isDelivery && isPickup) {
                        // TODO: check if double counting the discount if for both delivery types
                        bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                        bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);

                        bestPickupToppingsPrice = Math.min(discountedToppingsPrice, bestToppingsPrice);
                        bestDeliveryToppingsPrice = Math.min(discountedToppingsPrice, bestToppingsPrice);
                    } else if (isDelivery) {
                        bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                        bestDeliveryToppingsPrice = Math.min(discountedToppingsPrice, bestToppingsPrice);
                    } else if (isPickup) {
                        bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);
                        bestPickupToppingsPrice = Math.min(discountedToppingsPrice, bestToppingsPrice);
                    }
                }
            });

            // Return the lowest price
            product.deliveryPrice = bestDeliveryPrice + bestDeliveryToppingsPrice;
            product.pickupPrice = bestPickupPrice + bestPickupToppingsPrice;
        }

        return product;
    };

    return (
        <>
            <PizzaHeader pizza={LargeImage((dividePizza === 2 ? pizza2 : pizza)?.imagePublicId)} />
            <Section wide>
                {/* Sizes */}
                <RadioGroup options={sizes} active={size} setActive={setSize} />
                {/* Tools */}
                <div style={{ marginTop: '10px' }}>
                    {pizza?.canBeDivided && (
                        <Button
                            onClick={(e) => toggleDivision()}
                            active={dividePizza}
                            icon={<DivisionIcon color={dividePizza ? '#fff' : '#b51430'} />}
                        >
                            {transcript[lang].divide}
                        </Button>
                    )}
                </div>
            </Section>
            {/* Dropdown title */}
            <StickySection>
                <DropDown
                    title={dividePizza ? transcript[lang].nthHalf.replace('%%', 1) : null}
                    options={pizzas
                        .filter((p) => !dividePizza || p.canBeDivided)
                        .map((item) => ({
                            key: item.slug,
                            value: item?.slug,
                            label: item?.name[lang],
                        }))}
                    value={pizza?.slug}
                    onChange={handlePizzaChange}
                    onClick={(e) => dividePizza && setDividePizza(1)}
                    active={dividePizza === 1 || !dividePizza}
                    divided={dividePizza}
                />
                {!!dividePizza && (
                    <DropDown
                        title={transcript[lang].nthHalf.replace('%%', 2)}
                        options={pizzas
                            .filter((p) => p.canBeDivided)
                            .map((item) => ({
                                value: item?.slug,
                                label: item?.name[lang],
                            }))}
                        value={pizza2?.slug}
                        onChange={handlePizzaChange}
                        onClick={(e) => dividePizza && setDividePizza(2)}
                        active={dividePizza === 2}
                        divided={dividePizza}
                    />
                )}
            </StickySection>
            {/* <div> */}

            {/* <Button
                    onClick={(e) => toggleToppings()}
                    active={customToppings}
                    icon={<ToppingsIcon color={customToppings ? '#fff' : '#b51430'} />}
                    style={{ marginLeft: '20px' }}
                >
                    {transcript[lang].toppings}
                </Button> */}
            {/* </div> */}

            {/* Topping selections */}
            {
                <Section
                    wide
                    // title={
                    //     dividePizza
                    //         ? `${transcript[lang].toppings} - ${transcript[lang].nthHalf.replace('%%', dividePizza)}`
                    //         : transcript[lang].toppings
                    // }
                >
                    {flatToppings.length && (
                        <>
                            <p>
                                <b style={{ marginRight: '10px' }}>
                                    {dividePizza
                                        ? `${transcript[lang].toppings} - ${transcript[lang].nthHalf.replace(
                                              '%%',
                                              dividePizza
                                          )}`
                                        : transcript[lang].toppings}
                                    :
                                </b>
                                {selectedToppings && selectedToppings.map((topping) => topping?.name[lang]).join(', ')}
                            </p>
                        </>
                    )}

                    {customToppings &&
                        !!toppings &&
                        toppings.map((toppingGroup) => (
                            <CheckGroup
                                title={toppingGroup.category.name[lang]}
                                options={toppingGroup.toppings.map((topping) => ({
                                    id: topping.id,
                                    label: topping.name[lang],
                                    secondary: topping.maxAmount > 1,
                                }))}
                                values={dividePizza === 2 ? toppingsSelectionSide2 : toppingsSelectionSide1}
                                toggle={(val) => selectTopping(val)}
                                secondaryToggle={(val) => selectToppingAmount(val)}
                                secondaryLabel="2X"
                                valueKey="id"
                            />
                        ))}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </Section>
            }
            <HotBar
                title={transcript[lang].addToCart}
                subtitle={`${formatISK(bestPrice + bestToppingsPrice)} kr.`}
                action={handleAddToCart}
            />
        </>
    );
};

export default PizzaPage;
