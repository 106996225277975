import React, { useState, useEffect } from 'react';

// UI
import { Section, Form, Input, Select, CheckBox, Notice, CartItems } from '../components/UI';
import { AUTH_USER, REQUEST_CODE, CREATE_ORDER, VALIDATE_VALITOR_3D_SECURE } from '../graphql/mutations';

import { toast } from 'react-toastify';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setToken, decodeToken, getToken, deleteToken } from '../services/token';
import { mapCartToMutation } from '../services/cart';
import { addToCart, resetCart } from '../redux/actions/cart';
import { useBranches, useCastelloSettings } from '../hooks';

// Assets
import DeliverIcon from '../assets/icons/car.svg';
import PickupIcon from '../assets/icons/store.svg';
import ClosedIcon from '../assets/icons/closed.svg';

// Util
import zipCodes from '../services/zipCodes';
import transcript from '../i18n/CheckoutPage';
import { trackPurchase } from '../components/Analytics';
import NetgiroCheckoutForm from '../components/UI/NetgiroForms/NetgiroCheckoutForm';
import AurPaymentForm from '../components/UI/AurPaymentForm/AurPaymentForm';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

const CheckoutPage = (props) => {
    const [authUser] = useMutation(AUTH_USER);
    const [requestCode] = useMutation(REQUEST_CODE);
    const [createOrder] = useMutation(CREATE_ORDER);
    const [validateValitor3DSecure] = useMutation(VALIDATE_VALITOR_3D_SECURE);

    const [valitorReturnData, setValitorReturnData] = useState();
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [netgiroCheckoutData, setNetgiroCheckoutData] = useState();
    const [aurCheckoutData, setAurCheckoutData] = useState();

    const lang = useSelector((state) => state.lang);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);

    const [orderData, setOrderData] = useState(null);

    // SMS Auth
    const [auth, setAuth] = useState(false);
    const [pendingAuth, setPendingAuth] = useState(false);

    const [number, setNumber] = useState('');
    const [code, setCode] = useState('');

    // Delivery / pickup
    const [step, setStep] = useState(0); //1:
    const [deliveryMethod, setDeliveryMethod] = useState();
    const [deliveryAddress, setDeliveryAddress] = useState({
        zip: '',
        street: '',
        comment: '',
    });
    const [comment, setComment] = useState('');
    const [selectedBranch, setSelectedBranch] = useState();

    // Data hooks
    const { branches, loading } = useBranches();
    const { settings } = useCastelloSettings();

    // Facts
    const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    // Payment
    const totalPrice = cart?.reduce((acc, curr) => acc + curr.price, 0);
    const pickupTotal = cart?.reduce((acc, curr) => acc + (curr.pickupPrice || curr.price), 0);
    const deliveryTotal = cart?.reduce((acc, curr) => acc + (curr.deliveryPrice || curr.price), 0);
    const isFreeDelivery = !!cart?.find((item) => item.type === 'Offer' && item.deliveryAvail && !item.pickupAvail);

    const [clientName, setClientName] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('Card');
    const [card, setCard] = useState({ ccn: '', mm: '', yy: '', cvc: '' });
    const [termsAcceptance, setTermsAcceptance] = useState(false);
    const [valitor3DSecure, setValitor3DSecure] = useState(false);

    const [availableZipCodes, setAvailableZipCodes] = useState([]);
    const navigate = useNavigate();

    // TODO: check if open, return error if not
    useEffect(() => {
        if (branches && !availableZipCodes.length) {
            setAvailableZipCodes([
                ...new Set(
                    branches
                        .filter((b) => b.delivering)
                        .map((branch) => branch.homeDeliveryPostalCodes)
                        .flat()
                        .sort((a, b) => a - b)
                ),
            ]);
        }

        if (availableZipCodes && !deliveryAddress.zip && deliveryMethod === 'delivery') {
            setSelectedBranch(branches.find((b) => b.homeDeliveryPostalCodes.includes(availableZipCodes[0]))?.id);
        }
    }, [availableZipCodes, branches, deliveryAddress, deliveryMethod]);

    useEffect(() => {
        if (!auth) {
            let token = getToken();
            if (token) {
                token = decodeToken(token);
                token.exp * 1000 > new Date().getTime() ? setAuth(true) : deleteToken();
            }
        }
    }, [auth]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let cartParams = params.get('cart');
        if (cartParams) {
            cartParams = JSON.parse(cartParams);
            dispatch(resetCart());
            cartParams.map((cartItem) => {
                dispatch(addToCart(cartItem));
            });
        }

        window.addEventListener('message', (e) => {
            if (e.data.hasOwnProperty('valitorMessage')) {
                setValitorReturnData(e.data.valitorMessage);
            }
        });
    }, []);

    useEffect(() => {
        if (valitorReturnData) {
            confirmOrderAfter3DSecure();
        }

        return () => {
            setValitorReturnData(null);
        };
    }, [valitorReturnData]);

    if (!loading && branches.every((b) => !b.open) && branches.every((b) => !b.delivering)) {
        return <Notice message={transcript[lang].allClosed} icon={ClosedIcon} />;
    }

    const confirmOrderAfterAurOrNetgiro = (orderId) => {
        try {
            dispatch(resetCart());
            trackPurchase(
                deliveryMethod === 'delivery'
                    ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery)
                    : pickupTotal
            );

            navigate(`/order/${orderId}`);
            localStorage.setItem('lastOrder', JSON.stringify({ cart, deliveryMethod }));
        } catch (err) {
            setSubmitting(false);
            console.log(err);
            toast(transcript[lang].genericError, { type: 'error', position: 'bottom-right' });
        }
    };

    const confirmOrderAfter3DSecure = () => {
        validateValitor3DSecure({
            variables: {
                order: orderData.id,
                valitorMessage: {
                    cavv: valitorReturnData.cavv,
                    mdStatus: valitorReturnData.mdStatus,
                    xid: valitorReturnData.xid,
                    DsTransId: valitorReturnData['TDS2.dsTransID'],
                },
                card,
            },
        })
            .then((rsp) => {
                dispatch(resetCart());
                trackPurchase(
                    deliveryMethod === 'delivery'
                        ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery)
                        : pickupTotal
                );

                navigate(`/order/${orderData.id}`);
                localStorage.setItem('lastOrder', JSON.stringify({ cart, deliveryMethod }));
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
                toast(transcript[lang].genericError, { type: 'error', position: 'bottom-right' });
            });
    };

    const isOpen = (branch) => {
        //Checking if localhost
        if (window.location.hostname === 'localhost') return true;

        const day = new Date().getDay();
        const hour = new Date().getHours();
        const minute = new Date().getMinutes();

        let open = true;
        let openingHours = branch.openingHours[weekdays[day]];

        const hourFrom = Number(openingHours.from.slice(0, 2));
        const minuteFrom = Number(openingHours.from.slice(2, 4));

        const hourTo = Number(openingHours.to.slice(0, 2));
        const minuteTo = Number(openingHours.to.slice(2, 4));

        if (hourFrom >= hour) {
            open = hourFrom === hour && minuteFrom < minute;
        }

        if (hourTo <= hour) {
            open = hourTo === hour && minuteTo > minute;
        }

        return open;
    };

    // Auth actions
    const sendAuthSms = async () => {
        // validate input
        if (!number || !number.length) {
            toast(transcript[lang].phoneMissing, { type: 'error', position: 'bottom-right' });
            return;
        }

        if (!number.length === 7) {
            toast(transcript[lang].phoneInvalid, { type: 'error', position: 'bottom-right' });
            return;
        }

        // send auth sms
        try {
            await requestCode({ variables: { input: { phone: number } } });
            toast(transcript[lang].codeHasBeenSent, { type: 'success' });
            setPendingAuth(true);
        } catch (err) {
            toast(transcript[lang].authError, { type: 'error', position: 'bottom-right' });
        }
    };

    const submitAuthCode = async () => {
        if (!code || code.length !== 4) {
            toast(transcript[lang].authInvalid, { type: 'error', position: 'bottom-right' });
            return;
        }

        try {
            const { data } = await authUser({ variables: { input: { phone: number, code: Number(code) } } });
            setToken(data.userAuth.token);
            setAuth(true);
        } catch (err) {
            toast(transcript[lang].authWrong, { type: 'error', position: 'bottom-right' });
        }
    };

    const submitDeliveryForm = () => {
        if (!deliveryAddress.zip) {
            toast(transcript[lang].noZipCode, { type: 'error', position: 'bottom-right' });
            return;
        }

        if (!deliveryAddress.street) {
            toast(transcript[lang].noAddress, { type: 'error', position: 'bottom-right' });
            return;
        }

        setStep(1);
    };

    const submitPickupForm = (branch) => {
        setSelectedBranch(branch);
        setStep(1);
    };

    const submitPaymentSelectionForm = () => {
        if (!clientName || !clientName.length) {
            toast(transcript[lang].noName, { type: 'error', position: 'bottom-right' });
            return;
        }
        if (paymentMethod === 'Card') {
            setStep(2);
        } else if (paymentMethod === 'Netgiro') {
            handleNetgiroAndAur();
            setStep(3);
        } else if (paymentMethod === 'Aur') {
            handleNetgiroAndAur();
            setStep(5);
        } else {
            submitOrder();
        }
    };

    // Order submission
    const submitOrder = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        // TODO: validate and post route
        if (paymentMethod === 'Card' && !termsAcceptance) {
            toast(transcript[lang].policyMissing, { type: 'error', position: 'bottom-right' });
            setSubmitting(false);
            return;
        }

        // reroute to /order/id on completion
        try {
            let mappedCart = mapCartToMutation(cart);
            const { data } = await createOrder({
                variables: {
                    input: {
                        totalPrice:
                            deliveryMethod === 'delivery'
                                ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery)
                                : pickupTotal,
                        deliveryMethod,
                        branch: selectedBranch,
                        message: comment,
                        deliveryAddress:
                            deliveryMethod === 'delivery'
                                ? {
                                      address: deliveryAddress.street,
                                      city:
                                          zipCodes.find((zipCode) => zipCode.zip === deliveryAddress.zip)?.city ||
                                          'Ótilgreind',
                                      postalCode: deliveryAddress.zip,
                                      message: deliveryAddress.comment,
                                  }
                                : null,
                        cart: mappedCart,
                        name: clientName,
                        paymentMethod: paymentMethod,
                        card,
                    },
                },
            });

            dispatch(resetCart());
            trackPurchase(
                deliveryMethod === 'delivery'
                    ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery)
                    : pickupTotal
            );

            navigate(`/order/${data.createOrder.id}`);
            localStorage.setItem('lastOrder', JSON.stringify({ cart, deliveryMethod }));
        } catch (err) {
            setSubmitting(false);
            console.log(err);
            toast(transcript[lang].genericError, { type: 'error', position: 'bottom-right' });
        }
    };

    // TODO: handle closed disclaimer

    // Auth
    if (!auth) {
        return !pendingAuth ? (
            <Section title={transcript[lang].auth}>
                <Form
                    title={transcript[lang].phone}
                    ctaTitle={transcript[lang].sendCode}
                    ctaAction={() => sendAuthSms()}
                >
                    <p>{transcript[lang].authMessage}</p>
                    <Input
                        label={transcript[lang].phone}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        maxLength={7}
                    />
                </Form>
            </Section>
        ) : (
            <Section title={transcript[lang].auth}>
                <Form
                    title={transcript[lang].authCode}
                    ctaTitle={transcript[lang].authenticate}
                    ctaAction={() => submitAuthCode()}
                >
                    <p>{transcript[lang].authenticationDescription.replace('%%', number)}</p>
                    <Input label={transcript[lang].authCode} value={code} onChange={(e) => setCode(e.target.value)} />
                    <b style={{ cursor: 'pointer' }} onClick={(e) => sendAuthSms()}>
                        {transcript[lang].sendAgain}
                    </b>
                    <br />
                </Form>
            </Section>
        );
    }

    let canDeliver = true;
    let canPickup = true;

    let tempcart = cart.filter((item) => item.type === 'Offer');
    tempcart.map((item) => {
        if (!item.pickupAvail) {
            canPickup = false;
        }
        if (!item.deliveryAvail) {
            canDeliver = false;
        }
    });

    // Forms
    const deliveryMethodForm = (
        <Section title={transcript[lang].pickupOrDelivery}>
            <Form
                ctaTitle={transcript[lang].pickup}
                ctaAction={() => setDeliveryMethod('pickup')}
                ctaIcon={PickupIcon}
                disabled={!canPickup}
                secondaryCtaTitle={transcript[lang].deliver}
                secondaryCtaAction={() => {
                    setDeliveryMethod('delivery');
                    setDeliveryAddress({ ...deliveryAddress, zip: '' });
                }}
                secondaryCtaIcon={DeliverIcon}
                secondaryDisabled={
                    !canDeliver ||
                    (settings && deliveryTotal < settings.minAmountDelivery) ||
                    (branches && branches.every((b) => !b.delivering))
                }
            >
                <b>{transcript[lang].castelloDeliversTo}:</b>
                <p>{availableZipCodes.join(', ')}</p>

                {settings && totalPrice < settings.minAmountDelivery && (
                    <p>
                        Pöntun þarf að nema <b>{formatISK(settings.minAmountDelivery)}</b> kr. fyrir heimsendingu
                    </p>
                )}

                <Input
                    label={transcript[lang].comment}
                    placeholder={transcript[lang].commentPlaceHolder}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />

                {/* {settings && <p style={{ textAlign: 'right' }}>{transcript[lang].deliveryCosts.replace('%%', formatISK(settings.priceDelivery))}</p>} */}

                {/* TODO: laga skitamix */}
                <div
                    className="prices"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        margin: '10px 0',
                    }}
                >
                    <b>{formatISK(pickupTotal)} kr.</b>
                    <div>
                        <b>{formatISK(deliveryTotal)} kr.</b>
                        <br />
                        {!isFreeDelivery && <b style={{ opacity: 0.5 }}>+{formatISK(settings?.priceDelivery)} kr.</b>}
                    </div>
                </div>
            </Form>
        </Section>
    );

    const pickupForm = (
        <Section title={transcript[lang].pickupWhere}>
            {branches &&
                branches.map((branch, i) => (
                    <Form
                        key={i}
                        title={branch.name}
                        subtitle={`${branch.address.city} - ${
                            isOpen(branch) ? transcript[lang].open : transcript[lang].closed
                        }`}
                        ctaTitle={transcript[lang].pickup}
                        ctaAction={() => submitPickupForm(branch.id)}
                        disabled={!isOpen(branch)}
                    >
                        <p>
                            Þú getur komið að sækja eftir <b>{branch.estimatedPickupTime}</b> mínútur
                        </p>
                        <p>
                            {transcript[lang].sunThu}: {branch.openingHours['mon'].from}-{branch.openingHours['mon'].to}
                        </p>
                        <p>
                            {transcript[lang].friSat}: {branch.openingHours['fri'].from}-{branch.openingHours['fri'].to}
                        </p>
                        {/* <p>
                            {transcript[lang].estimatedDeliveryTime}:<br />{' '}
                            <b>
                                {branch.estimatedDeliveryTime} -{branch.estimatedDeliveryTime + 5}{' '}
                                {transcript[lang].minutes}
                            </b>
                        </p> */}
                    </Form>
                ))}
        </Section>
    );

    const deliveryForm = (
        <Section title={transcript[lang].deliverWhere}>
            <Form ctaTitle={transcript[lang].order} ctaAction={() => submitDeliveryForm()} loading={submitting}>
                <Select
                    label={transcript[lang].zip}
                    value={deliveryAddress.zip}
                    onChange={(e) => {
                        // Finding branch
                        setSelectedBranch(branches.find((b) => b.homeDeliveryPostalCodes.includes(e.target.value))?.id);
                        setDeliveryAddress({ ...deliveryAddress, zip: e.target.value });
                    }}
                    options={[
                        { value: '', label: transcript[lang].chooseZip },
                        ...availableZipCodes.map((zip) => ({ value: zip, label: zip })),
                    ]}
                />
                <p>
                    {branches.find((b) => b.id === selectedBranch)?.name} {transcript[lang].delivers}
                </p>
                <Input
                    label={transcript[lang].address}
                    placeholder={''}
                    value={deliveryAddress.street}
                    onChange={(e) => setDeliveryAddress({ ...deliveryAddress, street: e.target.value })}
                />
                <Input
                    label={transcript[lang].deliveryComment}
                    placeholder={transcript[lang].deliveryCommentDesc}
                    value={deliveryAddress.comment}
                    onChange={(e) => setDeliveryAddress({ ...deliveryAddress, comment: e.target.value })}
                />
            </Form>
        </Section>
    );

    const paymentSelectionForm = (
        <Section title={transcript[lang].yourOrder}>
            <Form
                title={transcript[lang].paymentMethod}
                ctaTitle={transcript[lang].order}
                ctaAction={() => submitPaymentSelectionForm()}
            >
                <CartItems cart={cart} lang={lang} fixed={false} />
                <CheckBox
                    checked={paymentMethod === 'Card'}
                    onChange={(e) => setPaymentMethod('Card')}
                    label={transcript[lang].card}
                />
                <CheckBox
                    checked={paymentMethod === 'Netgiro'}
                    onChange={(e) => setPaymentMethod('Netgiro')}
                    label={'Netgíro'}
                />
                <CheckBox checked={paymentMethod === 'Aur'} onChange={(e) => setPaymentMethod('Aur')} label={'Aur'} />
                <CheckBox
                    checked={paymentMethod === 'Pop'}
                    onChange={(e) => setPaymentMethod('Pop')}
                    label={transcript[lang].pop}
                />
                <Input
                    label={transcript[lang].name}
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                />
            </Form>
        </Section>
    );

    const handleValitor = async () => {
        if (paymentLoading) {
            return;
        }

        if (
            !card.ccn ||
            !card.mm ||
            !card.yy ||
            !card.cvc ||
            card.ccn.length !== 16 ||
            card.mm.length !== 2 ||
            card.yy.length !== 2 ||
            card.cvc.length !== 3
        ) {
            toast(transcript[lang].cardInvalid, { type: 'error', position: 'bottom-right' });
            setSubmitting(false);
            return;
        }

        setPaymentLoading(true);
        const realTotalPrice =
            deliveryMethod === 'delivery' ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery) : pickupTotal;

        try {
            let mappedCart = mapCartToMutation(cart);
            const { data } = await createOrder({
                variables: {
                    input: {
                        totalPrice: realTotalPrice,
                        deliveryMethod,
                        branch: selectedBranch,
                        message: comment,
                        deliveryAddress:
                            deliveryMethod === 'delivery'
                                ? {
                                      address: deliveryAddress.street,
                                      city:
                                          zipCodes.find((zipCode) => zipCode.zip === deliveryAddress.zip)?.city ||
                                          'Ótilgreind',
                                      postalCode: deliveryAddress.zip,
                                      message: deliveryAddress.comment,
                                  }
                                : null,
                        cart: mappedCart,
                        name: clientName,
                        paymentMethod: paymentMethod,
                        card,
                    },
                },
            });
            setValitor3DSecure(data.createOrder.cardVerificationRawResponse);
            setStep(4);

            setOrderData(data.createOrder);
            setPaymentLoading(false);
        } catch (err) {
            setPaymentLoading(false);
            console.log(err);
            toast(transcript[lang].genericError, { type: 'error', position: 'bottom-right' });
        }
    };

    const handleNetgiroAndAur = async () => {
        if (paymentLoading) {
            return;
        }

        setPaymentLoading(true);
        const realTotalPrice =
            deliveryMethod === 'delivery' ? deliveryTotal + (isFreeDelivery ? 0 : settings.priceDelivery) : pickupTotal;

        try {
            let mappedCart = mapCartToMutation(cart);
            const { data } = await createOrder({
                variables: {
                    input: {
                        totalPrice: realTotalPrice,
                        deliveryMethod,
                        branch: selectedBranch,
                        message: comment,
                        deliveryAddress:
                            deliveryMethod === 'delivery'
                                ? {
                                      address: deliveryAddress.street,
                                      city:
                                          zipCodes.find((zipCode) => zipCode.zip === deliveryAddress.zip)?.city ||
                                          'Ótilgreind',
                                      postalCode: deliveryAddress.zip,
                                      message: deliveryAddress.comment,
                                  }
                                : null,
                        cart: mappedCart,
                        name: clientName,
                        paymentMethod: paymentMethod,
                        card,
                    },
                },
            });

            if (data.createOrder.netgiroCheckoutData) {
                setNetgiroCheckoutData(data.createOrder);
            } else if (data.createOrder.aurCheckoutData) {
                setAurCheckoutData(data.createOrder);
            }
        } catch (err) {
            setPaymentLoading(false);
            console.log(err);
            toast(transcript[lang].genericError, { type: 'error', position: 'bottom-right' });
        }
    };

    const paymentForm = (
        <Section title={transcript[lang].yourOrder}>
            <Form id="valitorCheckout" loading={paymentLoading}>
                <Input
                    key="ccn"
                    onChange={(e) => setCard({ ...card, ccn: e.target.value })}
                    label={transcript[lang].ccn}
                    autoComplete="cc-number"
                    type="number"
                />
                <Input
                    key="mm"
                    onChange={(e) => setCard({ ...card, mm: e.target.value })}
                    label={transcript[lang].mm}
                    autoComplete="cc-exp-month"
                    type="number"
                />
                <Input
                    key="yy"
                    onChange={(e) => setCard({ ...card, yy: e.target.value })}
                    label={transcript[lang].yy}
                    autoComplete="cc-exp-year"
                    type="number"
                />
                <Input
                    key="cvc"
                    onChange={(e) => setCard({ ...card, cvc: e.target.value })}
                    label={transcript[lang].cvc}
                    autoComplete="cc-csc"
                    type="number"
                />

                <CheckBox
                    checked={termsAcceptance}
                    onChange={(e) => setTermsAcceptance(e.target.checked)}
                    label={transcript[lang].acceptPolicy}
                />
                <a style={{ width: '100%', textAlign: 'right' }} href={'/policy'} target="_blank">
                    <p>{transcript[lang].readPolicy}</p>
                </a>
                <button
                    type="button"
                    onClick={handleValitor}
                    className={paymentLoading ? 'loading' : ''}
                    disabled={!termsAcceptance || totalPrice <= 0}
                >
                    {transcript[lang].pay}
                </button>
            </Form>
        </Section>
    );

    const Valitor3DSecureForm = (
        <Section title={transcript[lang].valitor3DSecureFormTitle}>
            {valitor3DSecure && (
                <iframe
                    title="Valitor"
                    style={{ width: '100%', border: 'none', minHeight: 360 }}
                    srcDoc={valitor3DSecure}
                />
            )}
        </Section>
    );

    const AurPayment = (
        <Section title={transcript[lang].yourOrder}>
            <AurPaymentForm
                orderData={aurCheckoutData}
                onSuccess={(orderId) => confirmOrderAfterAurOrNetgiro(orderId)}
            />
        </Section>
    );

    const netgiroCheckout = (
        <Section title={transcript[lang].yourOrder}>
            <NetgiroCheckoutForm
                key="netgiroCheckout"
                orderData={netgiroCheckoutData}
                onSuccess={(orderId) => {
                    confirmOrderAfterAurOrNetgiro(orderId);
                }}
            ></NetgiroCheckoutForm>
        </Section>
    );

    let currentForm;
    switch (step) {
        case 0:
            currentForm = !deliveryMethod
                ? deliveryMethodForm
                : deliveryMethod === 'pickup'
                ? pickupForm
                : deliveryForm;
            break;
        case 1:
            currentForm = paymentSelectionForm;
            break;
        case 2:
            currentForm = paymentForm;
            break;
        case 3:
            currentForm = netgiroCheckout;
            break;
        case 4:
            currentForm = Valitor3DSecureForm;
            break;
        case 5:
            currentForm = AurPayment;
            break;
        default:
            currentForm = !deliveryMethod
                ? deliveryMethodForm
                : deliveryMethod === 'pickup'
                ? pickupForm
                : deliveryForm;
            break;
    }

    return currentForm;
};

export default CheckoutPage;
