import React from 'react';

// UI
import { Jumbotron } from '../components/UI';

import transcript from '../i18n/LandingPage';
import { useSelector } from 'react-redux';

import { useCastelloSettings } from '../hooks';

const LandingPage = (props) => {
    const lang = useSelector((state) => state.lang);
    const { settings } = useCastelloSettings();

    const isWednesday = new Date().getDay() === 3;
    console.log('HELLO');
    return (
        <Jumbotron
            title={
                isWednesday && new Date('11.18.2021') < new Date() && !settings?.frontTitle[lang]
                    ? transcript[lang].wednesdayTitle
                    : settings?.frontTitle[lang]
            }
            subtitle={
                isWednesday && new Date('11.18.2021') < new Date() && !settings?.frontSubtitle[lang]
                    ? transcript[lang].wednesdaySubtitle
                    : settings?.frontSubtitle[lang]
            }
            image={settings?.frontImageUrl}
            link={'/overview'}
            linkText={transcript[lang]?.cta}
        />
    );
};

export default LandingPage;
