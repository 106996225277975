import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { cart, lang } from './reducers';
import storage from 'redux-persist/lib/storage/session';
import { thunk } from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage, // Now correctly using session storage
    // Include any other config options as needed
};

const rootReducer = combineReducers({
    cart,
    lang,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initializeStore = (initialState) => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(thunk),
        preloadedState: initialState,
    });
};

const initializePersistor = (store) => {
    return persistStore(store);
};

export { initializeStore, initializePersistor };
