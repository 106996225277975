import React from 'react';
import { useSelector } from 'react-redux';
import './LocationCard.scss';

const days = {
    mon: {
        is: 'Mánudagur',
        en: 'Monday',
    },
    tue: {
        is: 'Þriðjudagur',
        en: 'Tuesday',
    },
    wed: {
        is: 'Miðvikudagur',
        en: 'Wednesday',
    },
    thu: {
        is: 'Fimmtudagur',
        en: 'Thursday',
    },
    fri: {
        is: 'Föstudagur',
        en: 'Friday',
    },
    sat: {
        is: 'Laugardagur',
        en: 'Saturday',
    },
    sun: {
        is: 'Sunnudagur',
        en: 'Sunday',
    },
};

const formatDate = (dateString) => {
    return dateString.slice(0, 2) + ':' + dateString.slice(2, 4);
};

// const maps = {
//     'Dalvegur 2':
//         'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1742.4827371855479!2d-21.879001586306863!3d64.10442257717327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d6734e6cabeda5%3A0xbb1093c319849c81!2sCastello%20Pizzeria!5e0!3m2!1sen!2sis!4v1700012429123!5m2!1sen!2sis',
//     'Lágmúli 7':
//         'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1740.3434915704588!2d-21.893489986305397!3d64.13856717719469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d67564b55770e3%3A0xdbd3747328638f94!2sCastello%20Pizzeria!5e0!3m2!1sen!2sis!4v1700012465724!5m2!1sen!2sis',
//     'Dalshrauni 13':
//         'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1744.032198855629!2d-21.944863686308018!3d64.07968537715777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48d60ced809a0119%3A0xfcfd441bc8b77ba3!2sCastello%20Pizzeria!5e0!3m2!1sen!2sis!4v1700012489264!5m2!1sen!2sis',
// };

const LocationCard = ({ title, subtitle, openingHours, times, action }) => {
    const lang = useSelector((state) => state.lang);
    const groupedOpeningHours = groupOpeningHours(openingHours, lang);
    console.log(groupedOpeningHours);

    return (
        <div className="location-card">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>

            {/* {!!maps[title] && (
                <iframe
                    title="Google Maps"
                    src={maps[title]}
                    width="100%"
                    height="200"
                    style={{ border: 'none', marginBottom: '10px' }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            )} */}

            <div className="opening-hours">
                {groupedOpeningHours &&
                    groupedOpeningHours.map((dayGroup) => (
                        <p>
                            {dayGroup.days} {formatDate(dayGroup.from)} - {formatDate(dayGroup.to)}
                        </p>
                    ))}
            </div>

            {action ? (
                <button onClick={action.action}>
                    <img src={action.icon} alt={action.label} /> {action.label}
                </button>
            ) : (
                <div className="times">
                    {times?.map((time) => (
                        <div className="time">
                            <p className="left">{time.label}</p>
                            <p className="right">{time.value} mín</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

function groupOpeningHours(openingHours, language) {
    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    const dayLabels = {
        en: {
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
            sun: 'Sun',
        },
        is: {
            mon: 'Mán',
            tue: 'Þri',
            wed: 'Mið',
            thu: 'Fim',
            fri: 'Fös',
            sat: 'Lau',
            sun: 'Sun',
        },
    };

    const groupedHours = [];

    let currentGroup = { days: [], from: '', to: '', isClosed: null };

    for (const day of days) {
        const { from, to, isClosed } = openingHours[day];

        if (currentGroup.days.length === 0) {
            currentGroup.days.push(dayLabels[language][day]);
            currentGroup.from = from;
            currentGroup.to = to;
            currentGroup.isClosed = isClosed;
        } else {
            if (currentGroup.from === from && currentGroup.to === to && currentGroup.isClosed === isClosed) {
                currentGroup.days.push(dayLabels[language][day]);
            } else {
                groupedHours.push(currentGroup);
                currentGroup = { days: [dayLabels[language][day]], from, to, isClosed };
            }
        }
    }

    groupedHours.push(currentGroup);

    return groupedHours.map((group) => {
        let dayLabel;
        if (group.days.length === 7) {
            dayLabel = language === 'en' ? 'All days' : 'Alla daga';
        } else if (
            group.days.length === 5 &&
            group.days[0] === dayLabels[language]['mon'] &&
            group.days[4] === dayLabels[language]['fri']
        ) {
            dayLabel = language === 'en' ? 'Mon - Fri' : 'Mán - Fös';
        } else if (
            group.days.length === 2 &&
            group.days[0] === dayLabels[language]['sat'] &&
            group.days[1] === dayLabels[language]['sun']
        ) {
            dayLabel = language === 'en' ? 'Sat - Sun' : 'Lau - Sun';
        } else {
            dayLabel = group.days.join(', ');
        }
        return { days: dayLabel, from: group.from, to: group.to, isClosed: group.isClosed };
    });
}

export default LocationCard;
