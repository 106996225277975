import React from 'react';
import { Section, TextArea, Input, CheckBox } from '../components/UI';

import transcript from '../i18n/JobPage';
import { useSelector } from 'react-redux';

const JobPage = () => {
    const lang = useSelector((state) => state.lang);

    return (
        <Section title={transcript[lang].apply}>
            <p>{transcript[lang].description}</p>
            <form className="form" action="https://formsubmit.co/castello2@simnet.is" method="POST">
                <Input label={transcript[lang].name} name="name" />
                <Input label={transcript[lang].email} name="email" />
                <Input label={transcript[lang].phone} name="telephone" />
                <Input label={transcript[lang].age} name="age" type={'number'} />
                <b>Starfshlutfall</b>
                <br />
                <br />
                <CheckBox label={transcript[lang].fullTime} name={'fullTime'} />
                <CheckBox label={transcript[lang].partTime} name={'partTime'} />
                <b>Starfsheiti</b>
                <br />
                <br />
                <CheckBox label={transcript[lang].driver} name={'driver'} />
                <CheckBox label={transcript[lang].service} name={'service'} />
                <CheckBox label={transcript[lang].baker} name={'baker'} />
                <br />
                <TextArea label={'Annað'} name={'comment'} />

                <button type="submit">{transcript[lang].applyButton}</button>
            </form>
        </Section>
    );
};

export default JobPage;
