import gql from 'graphql-tag';

export const REQUEST_CODE = gql`
    mutation requestCode($input: UserInputType!) {
        requestCode(userInputType: $input) {
            message
        }
    }
`;

export const AUTH_USER = gql`
    mutation userAuth($input: LoginUserInputType!) {
        userAuth(userInputType: $input) {
            id
            phone
            name
            token
        }
    }
`;

export const CALCULATE_SINGLE_PIZZA = gql`
    mutation calculateSinglePizza($input: UserOrderPizzaCalculateInputType!) {
        calculateSinglePizza(calculatePizzaInput: $input) {
            price
        }
    }
`;

export const REFRESH_CART = gql`
    mutation refreshCart($input: UserRefreshCartInputType!) {
        refreshCart(refreshCartInput: $input) {
            prices {
                pickupPrice
                deliveryPrice
            }
            pickupPrice
            deliveryPrice
            deliveryCost
        }
    }
`;

export const CREATE_ORDER = gql`
    mutation createOrder($input: UserOrderInputType!) {
        createOrder(createOrderInput: $input) {
            id
            cardVerificationRawResponse
            netgiroCheckoutData {
                TransactionId
                Success
                Message
            }
            aurCheckoutData {
                code
                message
            }
        }
    }
`;

export const VALIDATE_VALITOR_3D_SECURE = gql`
    mutation validateValitor3DSecure($order: ID!, $valitorMessage: ValitorMessageInputType!, $card: PaymentCardInput!) {
        validateValitor3DSecure(order: $order, valitorMessage: $valitorMessage, card: $card) {
            id
        }
    }
`;

export const VALIDATE_NETGIRO_ORDER = gql`
    mutation netgiroValidateOrder($transactionId: String!, $orderId: String!) {
        netgiroValidateOrder(transactionId: $transactionId, orderId: $orderId) {
            PaymentSuccessful
            Message
            Success
        }
    }
`;

export const VALIDATE_AUR_ORDER = gql`
    mutation aurValidateOrder($orderId: String!) {
        aurValidateOrder(orderId: $orderId) {
            code
            message
            state
            orderid
            amount
            transactionid
            transactiondate
        }
    }
`;
