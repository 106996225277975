import React from 'react';
import './LocationSection.scss';

const LocationSection = ({ title, subtitle, children, wide }) => (
    <section className={`locationSection ${wide ? 'wide' : ''}`}>
        <h1 className="title">{title}</h1>
        {subtitle && <h1 className="subtitle">{subtitle}</h1>}
        <div className="locationSectionContainer">{children}</div>
    </section>
);

export default LocationSection;
