import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { VALIDATE_AUR_ORDER } from '../../../graphql/mutations';
import aurLogo from '../../../assets/images/aur.svg';
import loader from '../../../dot-loader.gif';
import Section from '../Section/Section';
import Form from '../Form/Form';

export default function NetgiroCheckoutForm(props) {
    const { orderData, onSuccess } = props;

    const [aurValidateOrder] = useMutation(VALIDATE_AUR_ORDER);
    const [aurChecks, setAurChekcs] = useState(0);
    const [messageFromAur, setMessageFromAur] = useState();
    const [orderSuccessfullyCreated, setOrderSuccessfullyCreated] = useState(false);

    useEffect(() => {
        if (orderData && aurChecks < 105) {
            aurValidateOrder({
                variables: {
                    orderId: orderData.id,
                },
            }).then((rsp) => {
                const state = rsp.data.aurValidateOrder.state;
                const paymentSuccess = state === 'FINISHED';

                if (paymentSuccess) {
                    onSuccess(orderData.id);
                } else if (state === 'WAIT') {
                    setOrderSuccessfullyCreated(true);
                    setTimeout(() => {
                        setAurChekcs(aurChecks + 1);
                    }, 3000);
                } else if (state === 'CANCELLED') {
                    setMessageFromAur('Greiðsla fór ekki í gegn. Hætt hefur verið við pöntun.');
                }
            });
        }
    }, [aurChecks, orderData]);

    return (
        <Section>
            <Form id="aurCheckout" style={{ textAlign: 'center' }}>
                {
                    <>
                        {orderSuccessfullyCreated ? (
                            <h1 style={{ fontSize: 23 }}>Greiðslubeiðni send</h1>
                        ) : (
                            <h1 style={{ fontSize: 23 }}>Stofna greiðslubeiðni</h1>
                        )}

                        <img style={{ width: 100 }} src={aurLogo}></img>

                        <br />

                        <img
                            style={{
                                width: 150,
                                filter: 'grayscale(100%)',
                            }}
                            src={loader}
                        ></img>

                        {messageFromAur && <p>{messageFromAur}</p>}
                    </>
                }
            </Form>
        </Section>
    );
}
