import React from 'react';

const toppingsDelta = (originalToppings, selectedToppings) => {
    // Check added / removed toppings
    // Check different number toppings

    // console.log(originalToppings);

    if (!(originalToppings && selectedToppings)) {
        return '';
    }

    originalToppings = originalToppings.map((topping) => ({
        amount: topping.amount,
        id: topping.topping.id,
        name: topping.topping.name,
    }));

    let extraTopping = selectedToppings.filter((topping) => !originalToppings.map((t) => t.id).includes(topping.id));
    let missingToppings = originalToppings.filter((topping) => !selectedToppings.map((t) => t.id).includes(topping.id));

    let diffAmount = selectedToppings.filter((topping) => {
        let matchtable = originalToppings.find((ot) => {
            // console.log(ot.amount, topping.amount);
            // console.log(ot.id === topping.id);
            return ot.id === topping.id && ot.amount !== topping.amount;
        });
        // console.log(matchtable);
        return matchtable;
    });

    // console.log(extraTopping);

    return (
        <>
            {!!missingToppings.length && (
                <>
                    <b>Mínus:</b> {missingToppings.map((t) => t.name?.is).join(' ')}
                    <br />
                </>
            )}
            {!!extraTopping.length && (
                <>
                    <b>Extra:</b> {extraTopping.map((t) => `${t.amount} x ${t.name?.is} `)}
                    <br />
                </>
            )}
            {!!diffAmount.length && (
                <>
                    <b>Breytt magn:</b> {diffAmount.map((t) => `${t.amount} x ${t.name?.is} `)}
                    <br />
                </>
            )}
            {!missingToppings.length && !extraTopping.length && !diffAmount.length && <b>Óbreytt</b>}
        </>
    );
};

export { toppingsDelta };
