import React from 'react';
import { LocationCard, LocationSection } from '../components/UI';
import { useBranches } from '../hooks';

import transcript from '../i18n/LocationsPage';
import { useSelector } from 'react-redux';

const LocationsPage = () => {
    const { branches } = useBranches();
    const lang = useSelector((state) => state.lang);

    return (
        <LocationSection>
            {branches?.map((branch) => (
                <LocationCard
                    title={branch?.address?.street}
                    subtitle={`${branch?.address?.city} - ${
                        branch?.open ? transcript[lang].open : transcript[lang].closed
                    }`}
                    openingHours={branch.openingHours}
                    times={[
                        {
                            label: transcript[lang].pickup,
                            value: branch.estimatedPickupTime,
                        },
                        {
                            label: transcript[lang].delivered,
                            value: branch.estimatedDeliveryTime,
                        },
                    ]}
                />
            ))}
        </LocationSection>
    );
};

export default LocationsPage;
