import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const initGA = () => {
    ReactGA.initialize('UA-68458719-6')
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
    ReactPixel.pageView();
}

export const initPixel = () => {
    ReactPixel.init('238345850718655');
    ReactPixel.pageView();
}

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView();
}

// Later
export const logEvent = (category = '', action = '') => {
    if (category && action) {
        ReactGA.event({ category, action });
    }
}
export const logException = (description = '', fatal = false) => {
    if (description) {
        ReactGA.exception({ description, fatal })
    }
}

export const trackPurchase = (num) => {
    if (num) {
        window.dataLayer.push({ event: 'purchase', 'purchaseAmount': num });
        ReactPixel.track('Purchase', {
            value: num,
            currency: 'ISK',
        });
    }
}