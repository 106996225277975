const zipCodes = [
    {
        zip: '101',
        city: 'Reykjavík'
    },
    {
        zip: '102',
        city: 'Reykjavík'
    },
    {
        zip: '103',
        city: 'Reykjavík'
    },
    {
        zip: '104',
        city: 'Reykjavík'
    },
    {
        zip: '105',
        city: 'Reykjavík'
    },
    {
        zip: '107',
        city: 'Reykjavík'
    },
    {
        zip: '108',
        city: 'Reykjavík'
    },
    {
        zip: '109',
        city: 'Reykjavík'
    },
    {
        zip: '110',
        city: 'Reykjavík'
    },
    {
        zip: '111',
        city: 'Reykjavík'
    },
    {
        zip: '112',
        city: 'Reykjavík'
    },
    {
        zip: '113',
        city: 'Reykjavík'
    },
    {
        zip: '116',
        city: 'Reykjavík'
    },
    {
        zip: '121',
        city: 'Reykjavík'
    },
    {
        zip: '123',
        city: 'Reykjavík'
    },
    {
        zip: '124',
        city: 'Reykjavík'
    },
    {
        zip: '125',
        city: 'Reykjavík'
    },
    {
        zip: '127',
        city: 'Reykjavík'
    },
    {
        zip: '128',
        city: 'Reykjavík'
    },
    {
        zip: '129',
        city: 'Reykjavík'
    },
    {
        zip: '130',
        city: 'Reykjavík'
    },
    {
        zip: '132',
        city: 'Reykjavík'
    },
    {
        zip: '150',
        city: 'Reykjavík'
    },
    {
        zip: '155',
        city: 'Reykjavík'
    },
    {
        zip: '170',
        city: 'Seltjarnarnes'
    },
    {
        zip: '172',
        city: 'Seltjarnarnes'
    },
    {
        zip: '190',
        city: 'Vogar'
    },

    {
        zip: '200',
        city: 'Kópavogur'
    },
    {
        zip: '201',
        city: 'Kópavogur'
    },
    {
        zip: '202',
        city: 'Kópavogur'
    },
    {
        zip: '203',
        city: 'Kópavogur'
    },
    {
        zip: '210',
        city: 'Garðabær'
    },
    {
        zip: '212',
        city: 'Garðabær'
    },
    {
        zip: '220',
        city: 'Hafnarfjörður'
    },
    {
        zip: '221',
        city: 'Hafnarfjörður'
    },
    {
        zip: '222',
        city: 'Hafnarfjörður'
    },
    {
        zip: '225',
        city: 'Álftanes'
    },
    {
        zip: '230',
        city: 'Reykjanesbær'
    },
    {
        zip: '232',
        city: 'Reykjanesbær'
    },
    {
        zip: '233',
        city: 'Reykjanesbær'
    },
    {
        zip: '235',
        city: 'Reykjanesbær'
    },
    {
        zip: '240',
        city: 'Grindavík'
    },
    {
        zip: '245',
        city: 'Sandgerði'
    },
    {
        zip: '250',
        city: 'Garður'
    },
    {
        zip: '260',
        city: 'Reykjanesbær'
    },
    {
        zip: '270',
        city: 'Mosfellsbær'
    },
    {
        zip: '271',
        city: 'Mosfellsbær'
    },
    {
        zip: '276',
        city: 'Mosfellsbær'
    },

    {
        zip: '300',
        city: 'Akranes'
    },
    {
        zip: '301',
        city: 'Akranes'
    },
    {
        zip: '302',
        city: 'Akranes'
    },
    {
        zip: '310',
        city: 'Borgarnes'
    },
    {
        zip: '311',
        city: 'Borgarnes'
    },
    {
        zip: '320',
        city: 'Reykholt'
    },
    {
        zip: '340',
        city: 'Stykkishólmur'
    },
    {
        zip: '345',
        city: 'Flatey'
    },
    {
        zip: '350',
        city: 'Grundarfjörður'
    },
    {
        zip: '355',
        city: 'Ólafsvík'
    },
    {
        zip: '356',
        city: 'Snæfellsbær'
    },
    {
        zip: '360',
        city: 'Hellissandur'
    },
    {
        zip: '370',
        city: 'Búðardalur'
    },
    {
        zip: '371',
        city: 'Búðardalur'
    },
    {
        zip: '380',
        city: 'Reykhólahreppur'
    },

    {
        zip: '400',
        city: 'Ísafjörður'
    },
    {
        zip: '401',
        city: 'Ísafjörður'
    },
    {
        zip: '410',
        city: 'Hnífsdalur'
    },
    {
        zip: '415',
        city: 'Bolungarvík'
    },
    {
        zip: '420',
        city: 'Súðavík'
    },
    {
        zip: '425',
        city: 'Flateyri'
    },
    {
        zip: '430',
        city: 'Suðureyri'
    },
    {
        zip: '450',
        city: 'Patreksfjörður'
    },
    {
        zip: '451',
        city: 'Patreksfjörður'
    },
    {
        zip: '460',
        city: 'Tálknafjörður'
    },
    {
        zip: '465',
        city: 'Bíldudalur'
    },
    {
        zip: '470',
        city: 'Þingeyri'
    },
    {
        zip: '471',
        city: 'Þingeyri'
    },

    {
        zip: '500',
        city: 'Staður'
    },
    {
        zip: '510',
        city: 'Hólmavík'
    },
    {
        zip: '512',
        city: 'Hólmavík'
    },
    {
        zip: '520',
        city: 'Drangsnes'
    },
    {
        zip: '524',
        city: 'Árneshreppur'
    },
    {
        zip: '530',
        city: 'Hvammstangi'
    },
    {
        zip: '531',
        city: 'Hvammstangi'
    },
    {
        zip: '540',
        city: 'Blönduós'
    },
    {
        zip: '541',
        city: 'Blönduós'
    },
    {
        zip: '545',
        city: 'Skagaströnd'
    },
    {
        zip: '550',
        city: 'Sauðárkrókur'
    },
    {
        zip: '551',
        city: 'Sauðárkrókur'
    },
    {
        zip: '560',
        city: 'Varmahlíð'
    },
    {
        zip: '565',
        city: 'Hofsós'
    },
    {
        zip: '566',
        city: 'Hofsós'
    },
    {
        zip: '570',
        city: 'Fljót'
    },
    {
        zip: '580',
        city: 'Siglufjörður'
    },

    {
        zip: '600',
        city: 'Akureyri'
    },
    {
        zip: '601',
        city: 'Akureyri'
    },
    {
        zip: '602',
        city: 'Akureyri'
    },
    {
        zip: '603',
        city: 'Akureyri'
    },
    {
        zip: '610',
        city: 'Grenivík'
    },
    {
        zip: '611',
        city: 'Grímsey'
    },
    {
        zip: '620',
        city: 'Dalvík'
    },
    {
        zip: '621',
        city: 'Dalvík'
    },
    {
        zip: '625',
        city: 'Ólafsfjörður'
    },
    {
        zip: '630',
        city: 'Hrísey'
    },
    {
        zip: '640',
        city: 'Húsavík'
    },
    {
        zip: '641',
        city: 'Húsavík'
    },
    {
        zip: '645',
        city: 'Fosshóll'
    },
    {
        zip: '650',
        city: 'Laugar'
    },
    {
        zip: '660',
        city: 'Mývatn'
    },
    {
        zip: '670',
        city: 'Kópasker'
    },
    {
        zip: '671',
        city: 'Kópaskeri'
    },
    {
        zip: '675',
        city: 'Raufarhöfn'
    },
    {
        zip: '680',
        city: 'Þórshöfn'
    },
    {
        zip: '681',
        city: 'Þórshöfn'
    },
    {
        zip: '685',
        city: 'Bakkafjörður'
    },
    {
        zip: '690',
        city: 'Vopnafjörður'
    },

    {
        zip: '700',
        city: 'Fellabær'
    },
    {
        zip: '701',
        city: 'Egilsstaðir'
    },
    {
        zip: '710',
        city: 'Seyðisfjörður'
    },
    {
        zip: '715',
        city: 'Mjóifjörður'
    },
    {
        zip: '720',
        city: 'Borgarfjörður'
    },
    {
        zip: '730',
        city: 'Reyðarfjörður'
    },
    {
        zip: '735',
        city: 'Eskifjörður'
    },
    {
        zip: '740',
        city: 'Neskaupstaður'
    },
    {
        zip: '750',
        city: 'Fáskrúðsfjörður'
    },
    {
        zip: '755',
        city: 'Stöðvarfjörður'
    },
    {
        zip: '760',
        city: 'Breiðdalsvík'
    },
    {
        zip: '765',
        city: 'Djúpivogur'
    },
    {
        zip: '780',
        city: 'Höfn'
    },
    {
        zip: '781',
        city: 'Höfn'
    },
    {
        zip: '785',
        city: 'Öræfi'
    },

    {
        zip: '800',
        city: 'Selfoss'
    },
    {
        zip: '801',
        city: 'Selfoss'
    },
    {
        zip: '802',
        city: 'Selfoss'
    },
    {
        zip: '810',
        city: 'Hveragerði'
    },
    {
        zip: '815',
        city: 'Þorlákshöfn'
    },
    {
        zip: '816',
        city: 'Ölfus'
    },
    {
        zip: '820',
        city: 'Eyrarbakki'
    },
    {
        zip: '825',
        city: 'Stokkseyri'
    },
    {
        zip: '840',
        city: 'Laugarvatn'
    },
    {
        zip: '845',
        city: 'Flúðir'
    },
    {
        zip: '846',
        city: 'Flúðir'
    },
    {
        zip: '850',
        city: 'Hella'
    },
    {
        zip: '851',
        city: 'Hella'
    },
    {
        zip: '860',
        city: 'Hvolsvöllur'
    },
    {
        zip: '861',
        city: 'Hvolsvöllur'
    },
    {
        zip: '870',
        city: 'Vík'
    },
    {
        zip: '871',
        city: 'Vík'
    },
    {
        zip: '880',
        city: 'Kirkjubæjarklaustur'
    },
    {
        zip: '900',
        city: 'Vestmannaeyjar'
    },
    {
        zip: '902',
        city: 'Vestmannaeyjar'
    },
]

export default zipCodes;