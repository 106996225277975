import React from 'react';
import './Notice.scss';

const Notice = ({ message, icon }) => (
    <div className="notice">
        <img src={icon} alt={message} />
        <h1>{message}</h1>
    </div>
)

export default Notice;