import React from 'react';
import { Section, TextArea, Input, CheckBox, DropDown } from '../components/UI';

import transcript from '../i18n/ServicePage';
import { useSelector } from 'react-redux';

const ServicePage = () => {
    const lang = useSelector((state) => state.lang);

    return (
        <Section title={transcript[lang].apply}>
            <p>{transcript[lang].description}</p>
            <form className="form" action="https://formsubmit.co/castello2@simnet.is" method="POST">
                <Input label={transcript[lang].name} name="name" />
                <Input label={transcript[lang].email} name="email" />
                <Input label={transcript[lang].phone} name="telephone" />
                <br />
                <br />
                <Input label={transcript[lang].number} name="telephone" />

                <TextArea label={'Annað'} name={'comment'} />

                <button type="submit">{transcript[lang].applyButton}</button>
            </form>
        </Section>
    );
};

export default ServicePage;
