import { useQuery } from '@apollo/client';
import { ALL_DISCOUNTS } from '../graphql/queries'

function useDiscounts() {
    const { error, data, loading } = useQuery(ALL_DISCOUNTS, {
        notifyOnNetworkStatusChange: true,
    });

    if (loading && !data ?.activeDiscounts) return { loading, discounts: [], totalCount: null };
    if (error) return { loading, discounts: [], totalCount: null }

    return {
        discounts: data.activeDiscounts.edges.map(({ node }) => node),
        totalCount: data.activeDiscounts.totalCount,
        loading: false,
        refetch: false,
    };
}

export default useDiscounts;