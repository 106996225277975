import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getToken, deleteToken } from '../services/token';
import { getLang } from '../services/lang';

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken();
    const lang = getLang();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            lang: lang ? lang : 'is',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors)
    //   graphQLErrors.map(({ message, locations, path }) =>
    //     console.log(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //     ),
    //   );
    if (networkError && networkError.statusCode === 401) {
        // If token is unauthorized graphQL returns 401.
        // Do logic here to logout user and remove token and state or request a new token.
        deleteToken();
    }
});

const link = ApolloLink.from([authLink, errorLink, httpLink]);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
        addTypename: false, // Removes unwanted __typename.
    }),
});

// HOC apollo provider.
export default function ApolloProviderComponent({ children }) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
