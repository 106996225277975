import React from 'react';
import './Product.scss';

const Product = ({ title, description, variantPrices, price, image, action, icon, message, pizza, disabled }) => (
    <div
        className={`product-card ${disabled ? 'disabled' : ''}`}
        onClick={(e) => pizza && action && !disabled && action()}
    >
        <div className="cardBG"></div>
        <h2 className="pizzaName">{title}</h2>
        <p className="toppings">{description}</p>
        {message && <p className="message">{message}</p>}
        {!!variantPrices && (
            <div className="variant-prices">
                {variantPrices.map((varPrice, i) => (
                    <div className="pizzaPrice" key={i}>
                        <b className="var-label">{varPrice.label}</b>
                        <b className="var-price">{varPrice.price} kr.</b>
                    </div>
                ))}
            </div>
        )}
        {price && (
            <button className="orderBtn" onClick={(e) => !pizza && action && action()}>
                {icon && <img className="icon" src={icon} alt="Panta" />} {price ? price : 'Panta'}
            </button>
        )}
        <div className="img-container">
            <img class="pizzaImg" src={image} alt={title} />
        </div>
    </div>
);

export default Product;
