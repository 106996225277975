import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { VALIDATE_NETGIRO_ORDER } from '../../../graphql/mutations';

import loader from '../../../dot-loader.gif';
import Form from '../Form/Form';
import Section from '../Section/Section';

export default function NetgiroCheckoutForm(props) {
    const { orderData, onSuccess } = props;

    const [netgiroValidateOrder] = useMutation(VALIDATE_NETGIRO_ORDER);
    const [netgiroChecks, setNetgiroChekcs] = useState(0);
    const [messageFromNetgiro, setMessageFromNetgiro] = useState();
    const [orderSuccessfullyCreated, setOrderSuccessfullyCreated] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (orderData && netgiroChecks < 105) {
            netgiroValidateOrder({
                variables: {
                    transactionId: orderData.netgiroCheckoutData.TransactionId,
                    orderId: orderData.id,
                },
            })
                .then((rsp) => {
                    const paymentSuccess = rsp.data.netgiroValidateOrder.PaymentSuccessful;
                    const message = rsp.data.netgiroValidateOrder.Message;

                    setOrderSuccessfullyCreated(rsp.data.netgiroValidateOrder.Success);
                    setMessageFromNetgiro(message);

                    if (paymentSuccess) {
                        onSuccess(orderData.id);
                    } else {
                        setTimeout(() => {
                            setNetgiroChekcs(netgiroChecks + 1);
                        }, 3000);
                    }
                })
                .catch((err) => {
                    setErrorMessage(
                        'Ekki tókst að rukka pöntun. Ertu viss um að þú eigir Netgíró aðgang? Ef ekki þá þarftu fyrst að stofna hann á netgiro.is'
                    );
                });
        }
    }, [netgiroChecks, orderData]);

    return (
        <Section>
            <Form id="netgiroCheckout" style={{ textAlign: 'center' }}>
                <div key={props.key}>
                    {
                        <>
                            {orderSuccessfullyCreated ? (
                                <h1 style={{ fontSize: 23 }}>Pöntun send til Negríró</h1>
                            ) : (
                                <h1 style={{ fontSize: 23 }}>Stofna Netgíró pöntun</h1>
                            )}

                            {!errorMessage && <img style={{ width: 150 }} src={loader}></img>}

                            {messageFromNetgiro && <p>{messageFromNetgiro}</p>}
                            {errorMessage && <p>{errorMessage}</p>}
                        </>
                    }
                </div>
            </Form>
        </Section>
    );
}
