import React, { useEffect, useState } from 'react';
import { Section, Form, CartItems } from '../components/UI';
import { useQuery } from '@apollo/client';
import { MY_ORDER } from '../graphql/queries';
import { useBranches, useCastelloSettings } from '../hooks';
import { useSelector } from 'react-redux';

import { useToppings } from '../hooks';
import transcript from '../i18n/OrderPage.json';
import { useParams } from 'react-router';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');
const OrderPage = (props) => {
    // TODO: get branch waiting time
    // const { settings } = useCastelloSettings();

    const lang = useSelector((state) => state.lang);

    // Fetching order
    const { id: orderId } = useParams();

    const searchParams = new URLSearchParams(window.location.search);
    const netgiroTransactionId = searchParams.get('transactionid');
    const { data, loading } = useQuery(MY_ORDER, {
        variables: { id: orderId, netgiroTransactionId: netgiroTransactionId },
    });

    const { toppings } = useToppings();
    const flatToppings = toppings.map((t) => t.toppings).flat();

    const [orderError, setOrderError] = useState(false);

    useEffect(() => {
        if (!data) {
            setOrderError(true);
        } else {
            setOrderError(false);
            if (data.myOrder.paymentMethod === 'Netgiro' && data.myOrder.status === 'due') {
                // TODO: validate netgiro payload
            }
            if (data.myOrder.paymentMethod === 'Aur' && data.myOrder.status === 'due') {
                // TODO: validate aur payload
            }
        }
    }, [data]);

    if (loading) {
        return (
            <Section>
                <p>Hleður inn pöntun</p>
            </Section>
        );
    }

    if (orderError) {
        return (
            <Section title={'Villa við greiðslu'}>
                <p>
                    Greiðsla hefur ekki verið sannreynd, vinsamlegast hafðu samband við starfsmenn Castello.
                    <br />
                    <b>577 3333</b>
                </p>
            </Section>
        );
    }

    return (
        <Section
            title={transcript[lang].orderComplete}
            subtitle={(data?.myOrder.deliveryMethod === 'pickup'
                ? transcript[lang].waitingTime
                : transcript[lang].deliveryTime
            ).replace(
                '%%',
                data?.myOrder.deliveryMethod === 'pickup'
                    ? data?.myOrder.branch.estimatedPickupTime
                    : data?.myOrder.branch.estimatedDeliveryTime
            )}
        >
            {data && (
                <Form
                    title={
                        data?.myOrder.deliveryMethod === 'pickup' ? transcript[lang].pickup : transcript[lang].delivery
                    }
                    subtitle={data?.myOrder.branch.name}
                >
                    <CartItems
                        cart={data?.myOrder.cart.map((item) => {
                            const { payload1, payload2 } = item;
                            let newItem = { ...item }; // create a new object to avoid mutation

                            if (payload1) {
                                newItem.baseToppings1 = JSON.parse(payload1).toppings?.map((t) => {
                                    let topping = { ...t };
                                    topping.topping.id = topping.topping._id;
                                    return topping;
                                });
                            }

                            if (payload2) {
                                newItem.name2 = JSON.parse(payload2).name;
                                newItem.baseToppings2 = JSON.parse(payload2).toppings?.map((t) => {
                                    let topping = { ...t };
                                    topping.topping.id = topping.topping._id;
                                    return topping;
                                });
                            }

                            newItem.toppingsSelectionSide1 = item.toppingsSelectionSide1?.map((t) => ({
                                ...t,
                                id: t.toppingId,
                            }));

                            newItem.toppingsSelectionSide2 = item.toppingsSelectionSide2?.map((t) => ({
                                ...t,
                                id: t.toppingId,
                            }));

                            return newItem;
                        })}
                        lang={lang}
                        fixed={false}
                    />
                    {!!data?.myOrder?.totalDeliveryPrice && (
                        <p>
                            {transcript[lang].shipping} <b>{formatISK(data?.myOrder?.totalDeliveryPrice)} kr.</b>
                        </p>
                    )}
                    <p>
                        {transcript[lang].total} <b>{formatISK(data?.myOrder?.totalPrice)} kr.</b>
                    </p>
                    <p>
                        {transcript[lang].totalVAT} {formatISK(data?.myOrder?.totalVat)} kr.
                    </p>
                </Form>
            )}
        </Section>
    );
};

export default OrderPage;
