import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Drawer.scss';



const Drawer = ({ links, footer, action, actionLabel, secondaryAction, secondaryActionImage, onClose }) => {
    useEffect(() => {
        // Disable body scroll side cart is open
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="drawer-container">
            <div className="drawer">
                <div className="drawerHeadingList">
                    {links.map(link =>
                        <Link to={link.path} onClick={e => onClose()}>
                            <h2 className="drawerHeadings">{link.label}</h2>
                        </Link>
                    )}
                </div>
                <div className="footer">{footer}</div>
                <button className="drawer-action" onClick={e => action()}>
                    {actionLabel}
                </button>
                <button className="drawer-secondary-action">
                    <a href={secondaryAction}>
                        <img src={secondaryActionImage} alt="" />
                    </a>
                </button>
            </div>
            <div className="invisible-container" onClick={e => onClose()} />
        </div>
    )
}

export default Drawer;