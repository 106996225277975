export const mapCartToMutation = cart => {
	try {
		return cart.map(cartItem => ({
				type: cartItem.type,
				size: (cartItem.type === 'Pizza') ? cartItem.size : null,
				id: cartItem.id,
				id2: cartItem.id2,
				details: cartItem.type === 'Offer' ? cartItem.details : null,
				toppingsSelectionSide1: (cartItem.type === 'Pizza') ? cartItem.toppingsSelectionSide1.map(topping => ({
						toppingId: topping.id,
						amount: topping.amount
				})) : null,
				toppingsSelectionSide2: (cartItem.type === 'Pizza') ? cartItem.toppingsSelectionSide2.map(topping => ({
						toppingId: topping.id,
						amount: topping.amount
				})) : null,
				isDivided: (cartItem.type === 'Pizza') ? !!(cartItem.isDivided) : false,
		}))
	} catch (err) {
		return null;
	}
}
