import React from 'react';
import './StickySection.scss';

const StickySection = ({ title, subtitle, children, wide }) => (
    <section className={`stickySection ${wide ? 'wide' : ''}`}>
        <h1 className="title">{title}</h1>
        {subtitle && <h1 className="subtitle">{subtitle}</h1>}
        {children}
    </section>
);

export default StickySection;
