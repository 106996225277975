import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { CartItems } from '../';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../../../redux/actions/cart';
import { useBranches, useMenuItems } from '../../../hooks';

import { isIosApp } from '../../../services/ios';

import './Cart.scss';
import { Link } from 'react-router-dom';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

export default function Cart({ visible, toggle }) {
    const lang = useSelector((state) => state.lang);
    const cart = useSelector((state) => state.cart);

    const dispatch = useDispatch();

    const { branches, loading } = useBranches();
    const closed = !loading && branches.every((b) => !b.open) && branches.every((b) => !b.delivering);

    const [showUpsell, setShowUpsell] = useState(false);
    const { menuItems } = useMenuItems();

    const needsUpsell =
        !cart.find((c) => c.category?.name['en'] === 'Dips') || !cart.find((c) => c.category?.name['en'] === 'Drinks');
    console.log(needsUpsell);

    function removeItem(index) {
        dispatch(removeFromCart(index));
    }

    useEffect(() => {
        // Disable body scroll side cart is open
        setShowUpsell(false);
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="cart-container">
            {showUpsell ? (
                <div className="cart-modal">
                    <button className="backButton" onClick={() => setShowUpsell(false)}>
                        {`<- Tilbaka`}
                    </button>
                    {!cart.find((c) => c.category?.name['en'] === 'Drinks') && (
                        <>
                            <h1>Má bjóða þér drykk með?</h1>
                            <Link to={'/drinks'} onClick={toggle}>
                                <div className="imgContainer">
                                    <img
                                        src="https://res.cloudinary.com/dj0wwxv7z/w_550/castello/menu/5eaaec5bc011810ceb0c179f.png"
                                        alt="Drykkir"
                                    />
                                </div>
                                <button className="cart-upsell-button">Já takk - skoða drykki</button>
                            </Link>
                        </>
                    )}

                    {!cart.find((c) => c.category?.name['en'] === 'Dips') && (
                        <>
                            <h1>Má bjóða þér sósu með?</h1>
                            <Link to={'/dips'} onClick={toggle}>
                                <div className="imgContainer">
                                    <img
                                        src="https://res.cloudinary.com/dj0wwxv7z/w_550/castello/menu/5f7f3d5c75424c001704251b.png"
                                        alt="Drykkir"
                                    />
                                </div>
                                <button className="cart-upsell-button">Já takk - Velja sósu</button>
                            </Link>
                        </>
                    )}

                    <div className="checkoutBtn">
                        <a href="/checkout">
                            <button className="cart-button" style={{ backgroundColor: 'black' }}>
                                <h2 className="panta">
                                    {closed ? (lang === 'is' ? 'Lokað' : 'Closed') : lang === 'is' ? 'Panta' : 'Order'}
                                </h2>
                                <p className="cta-subtitle">
                                    {formatISK(
                                        cart.reduce(
                                            (curr, acc) =>
                                                (acc.pickupPrice
                                                    ? Math.min(acc.pickupPrice, acc.deliveryPrice)
                                                    : acc.price) + curr,
                                            0
                                        )
                                    )}{' '}
                                    kr.
                                </p>
                            </button>
                        </a>
                    </div>
                </div>
            ) : (
                <div className="cart-modal">
                    <CartItems cart={cart} lang={lang} removeItem={removeItem} />
                    {/* 

                <div className="uppsellBtn" onClick={toggle}>
                    {!cart.find((c) => c.category?.name['en'] == 'Dips') ? (
                        <Link to={'/dips'}>
                            <button>{lang === 'is' ? 'Vantar ekki sósu lika?' : 'Are you missing a dip?'}</button>
                        </Link>
                    ) : !cart.find((c) => c.category?.name['en'] == 'Drinks') ? (
                        <Link to={'/drinks'}>
                            <button>
                                {lang === 'is' ? 'Ekki gleyma að drekka með!' : "Don't forget the drinks"}
                            </button>
                        </Link>
                    ) : (
                        ''
                    )}
                </div>
                
                */}
                    <div className="checkoutBtn">
                        {needsUpsell ? (
                            <div
                                onClick={(e) => {
                                    setShowUpsell(true);
                                }}
                            >
                                <button className="cart-button">
                                    <h2 className="panta">
                                        {closed
                                            ? lang === 'is'
                                                ? 'Lokað'
                                                : 'Closed'
                                            : lang === 'is'
                                            ? 'Panta'
                                            : 'Order'}
                                    </h2>
                                    <p className="cta-subtitle">
                                        {formatISK(
                                            cart.reduce(
                                                (curr, acc) =>
                                                    (acc.pickupPrice
                                                        ? Math.min(acc.pickupPrice, acc.deliveryPrice)
                                                        : acc.price) + curr,
                                                0
                                            )
                                        )}{' '}
                                        kr.
                                    </p>
                                </button>
                            </div>
                        ) : (
                            <a href="/checkout">
                                <button className="cart-button">
                                    <h2 className="panta">
                                        {closed
                                            ? lang === 'is'
                                                ? 'Lokað'
                                                : 'Closed'
                                            : lang === 'is'
                                            ? 'Panta'
                                            : 'Order'}
                                    </h2>
                                    <p className="cta-subtitle">
                                        {formatISK(
                                            cart.reduce(
                                                (curr, acc) =>
                                                    (acc.pickupPrice
                                                        ? Math.min(acc.pickupPrice, acc.deliveryPrice)
                                                        : acc.price) + curr,
                                                0
                                            )
                                        )}{' '}
                                        kr.
                                    </p>
                                </button>
                            </a>
                        )}
                    </div>
                </div>
            )}

            <div className="cart-background" onClick={toggle} />
        </div>
    );
}

//https://ios.castello.is/checkout?cart=${JSON.stringify(cart)}
