// Detects if device is on iOS
const isIosApp = () => {
    return window.navigator.userAgent.toLowerCase().includes('iosapp');
};
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

const isInIosBrowser = () => window.location.href.includes('ios');

const isValitorReady = () => isIos() && isInIosBrowser();

// Detects if device is in standalone mode
// const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;
// const isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

// Checks if should display install popup notification:
// const isIOSPWA = () => isIos() && isInStandaloneMode();

// function isInStandaloneMode() {
//     // For iOS
//     if (window.navigator.standalone) return true;

//     // If neither is true, it's not installed
//     return false;
// }

export { isIos, isInIosBrowser, isValitorReady, isIosApp };
