import React from 'react';
import { Section, LocationCard } from '../components/UI';

import transcript from '../i18n/PolicyPage';
import { useSelector } from 'react-redux';

const PolicyPage = () => {
    const lang = useSelector(state => state.lang);

    return (
        <Section title={'Skilmálar Castello'} wide>
            <b>Almennt</b>
            <p>Castello áskilur sér rétt til að hætta við pantanir, t.d. vegna rangra verðupplýsinga og einnig að breyta verðum eða hætta að bjóða upp á vörutegundir fyrirvaralaust. Áskilinn er réttur til að staðfesta pantanir símleiðis.</p>
            <p>Sendingarkostnaður bætist við verð vöru í lok kaupferils og áður en greiðsla fer fram.</p>

            <b>Skilafrestur og endurgreiðsluréttur</b>
            <p>Kvittun fyrir vörukaupunum þarf að fylgja með. Endurgreiðsla er framkvæmd með innleggsnótu eða endurgreiðslu á kreditkort. Flutnings- og póstburðargjöld eru ekki endurgreidd. Kostnaður við endursendingu er á ábyrgð kaupanda. Vinsamlegast hafið samband við Castello ehf. með spurningar.</p>

            <b>Skattar og gjöld</b>
            <p>Öll verð í netversluninni eru með VSK og reikningar eru gefnir út með VSK. Uppgefin verð eru í ISK. Verð sem birtist í vefversluninni er ýmist með 11% eða 24% virðisaukaskatti, allt eftir gerð vöru.</p>

            <b>Greiðslumáti</b>
            <p>Með greiðslukorti eða debetkortum: Viðskiptavinir fá senda staðfestingu þegar greiðsla hefur borist og pöntun verður send. Mögulegt er að greiða pöntun með Visa og Mastercard, kredit eða debet kortum.</p>

            <b>Varnarþing</b>
            <p>Ákvæði og skilmála þessa ber að túlka í samræmi við íslensk lög. Komi upp ágreiningur eða telji einhver að hann eigi kröfu á hendur Castello á grundvelli ákvæða og skilmála, verður slíkum ágreiningi eða kröfu vísað til meðferðar hjá íslenskum dómstólum.</p>

            <b>Trúnaður</b>
            <p>Seljandi heitir kaupanda fullum trúnaði um allar þær upplýsingar sem kaupandi gefur upp í tengslum við viðskiptin. Upplýsingar verða ekki afhentar þriðja aðila undir neinum kringumstæðum.</p>

            <b>Aðrar upplýsingar </b>
            <ul>
                <li>Ada ehf.</li>
                <li>Dalvegi 2, 201 Kópavogur</li>
                <li>577 3333</li>

                <li>Netfang: castello@castello.is</li>
                <li>Kt: 430407 1550</li>
                <li>VSK Númer 95255</li>
            </ul>
            <ul>
                <li>Castello ehf.</li>
                <li>Dalshraun 13, 220 Hafnarfjörður</li>
                <li>577 3333</li>

                <li>Netfang: castello@castello.is</li>
                <li>Kt: 670409 0230</li>
                <li>VSK Númer 102413</li>
            </ul>

            <b>Öryggi</b>
            <p>Það er öruggt að versla í vefverslun Castello.is. Greiðslur með greiðslukortum fara í gegnum örugga greiðslusíðu Borgunar. Farið er með öll gögn og upplýsingar sem trúnaðarmál. Gögn og upplýsingar eru ekki afhentar til þriðja aðila</p>
        </Section>
    )
}

export default PolicyPage;