import React, { useEffect, useState } from 'react';
import { Section, List, CartItems, Form } from '../components/UI';
import { useMenuCategories } from '../hooks';
import { addToCart, resetCart } from '../redux/actions/cart';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { REFRESH_CART } from '../graphql/mutations';
import { mapCartToMutation } from '../services/cart';

// Assets
import arrRight from '../assets/icons/arrow_right.svg';

// i18n
import transcript from '../i18n/OverviewPage';
import { useSelector } from 'react-redux';
import { isIosApp } from '../services/ios';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

const OverviewPage = (props) => {
    const dispatch = useDispatch();
    const [lastOrder, setLastOrder] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const { menuCategories } = useMenuCategories();
    const [refreshCart] = useMutation(REFRESH_CART);
    const lang = useSelector((state) => state.lang);

    const links = [
        { label: transcript[lang].offers, path: '/offers' },
        { label: transcript[lang].pizzas, path: '/pizzas' },
        ...menuCategories.map((cat) => ({ path: `/${cat.slug}`, label: cat.name[lang] })),
    ];

    useEffect(() => {
        let lastOrder = localStorage.getItem('lastOrder') || null;
        setLastOrder(JSON.parse(lastOrder));
    }, [setLastOrder]);

    useEffect(() => {
        const refreshLastOrder = async () => {
            try {
                const input = {
                    cart: mapCartToMutation(lastOrder.cart),
                    deliveryMethod: lastOrder.deliveryMethod,
                };

                const { data } = await refreshCart({ variables: { input } });
                setPriceData(data.refreshCart);
                const newCart = lastOrder.cart.map((item, index) => {
                    let { pickupPrice, deliveryPrice } = data.refreshCart.prices[index];
                    return {
                        ...item,
                        pickupPrice,
                        deliveryPrice,
                    };
                });
                setLastOrder({ ...lastOrder, cart: newCart });
            } catch (err) {
                // delete order on error.
                // localStorage.removeItem('lastOrder')
            }
        };
        if (lastOrder && !priceData) {
            refreshLastOrder(lastOrder);
        }
    }, [refreshCart, lastOrder, priceData]);

    const orderLast = () => {
        dispatch(resetCart());

        if (isIosApp()) {
            window.open(`https://castello.netlify.app/checkout?cart=${JSON.stringify(lastOrder.cart)}`, '_blank');
        } else {
            lastOrder.cart.map((cartItem) => dispatch(addToCart(cartItem)));
            props.history.push({ pathname: `/checkout` });
        }
    };

    return (
        <>
            <Section title={transcript[lang].title}>
                <List items={links} listIcon={arrRight} />
                <em>v. 1.20</em>
                {priceData && (
                    <Form
                        title={transcript[lang].sameAsLast}
                        ctaTitle={`${formatISK(Math.min(priceData.pickupPrice, priceData.deliveryPrice))} kr.`}
                        ctaAction={() => orderLast()}
                    >
                        <CartItems cart={lastOrder.cart} lang={lang} fixed={false} />
                    </Form>
                )}
            </Section>
        </>
    );
};

export default OverviewPage;
