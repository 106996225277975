import React from 'react';
import RemoveIcon from './assets/remove_icon.svg';
import { toppingsDelta } from '../../../services/PizzaHelpers';
import './CartItems.scss';
import { useToppings } from '../../../hooks';

const formatISK = (price) => (price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0');

const sizeChart = {
    small: { en: 'Small', is: 'Lítil' },
    medium: { en: 'Medium', is: 'Miðlungs' },
    large: { en: 'Large', is: 'Stór' },
};

const CartItems = ({ cart, lang, removeItem, fixed = true }) => {
    console.log(cart);
    const { toppings } = useToppings();
    const flatToppings = toppings.flatMap((t) => t.toppings);

    const renderPizzaName = (name, type, isDivided, name2) => {
        if (typeof name === 'string') {
            return name;
        } else if (type === 'Pizza' && isDivided && name !== name2) {
            return `${name[lang]} // ${name2[lang]}`;
        } else {
            return name[lang];
        }
    };

    const renderToppings = (baseToppings, toppingsSelection, isDivided, side) => {
        if (flatToppings.length) {
            return (
                <>
                    {side}:
                    {toppingsDelta(
                        baseToppings,
                        toppingsSelection.map((topping) => ({
                            ...topping,
                            name: flatToppings.find((t) => t.id === topping.id)?.name,
                        }))
                    )}
                    <br />
                </>
            );
        }
    };

    return (
        <div className={`cart-items ${fixed ? 'fixed' : ''}`}>
            {cart.map((item, i) => (
                <div className="cart-item" key={i}>
                    <h3 className="item-name">
                        {renderPizzaName(item.name, item.type, item.isDivided, item.name2)}
                        {item.type === 'Pizza' && item.size ? ` ( ${sizeChart[item.size][lang]} )` : ''}
                    </h3>
                    {removeItem && (
                        <div className="remove" onClick={() => removeItem(i)}>
                            <img src={RemoveIcon} alt="Remove from cart" />
                        </div>
                    )}
                    <p className="item-description">
                        {item.type === 'Pizza' ? (
                            <>
                                {renderToppings(item.baseToppings1, item.toppingsSelectionSide1, item.isDivided, 'H1')}
                                {renderToppings(item.baseToppings2, item.toppingsSelectionSide2, item.isDivided, 'H2')}
                            </>
                        ) : (
                            item.description &&
                            (typeof item.description === 'string' ? item.description : item.description[lang] || '')
                        )}
                    </p>
                    <div className="item-price">
                        {formatISK(
                            item.pickupPrice && item.deliveryPrice
                                ? Math.min(item.pickupPrice, item.deliveryPrice)
                                : item.price
                        )}{' '}
                        kr.
                    </div>
                </div>
            ))}
            <div className="padding-space" />
        </div>
    );
};

export default CartItems;
