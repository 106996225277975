import React from 'react';
import './CheckBox.scss';

const CheckBox = ({ label, link, ...rest }) => (
    <div className="checkbox-container">
        <label className="checkbox-label">
            {link ?
                <a href={link} target="_blank">
                    <p className="label">{label}</p>
                </a>
                :
                <p className="label">{label}</p>
            }
            <input type="checkbox" {...rest} />
            <span className="checkmark"></span>
        </label>
    </div>
)

export default CheckBox;