import React from 'react';
import './DropDown.scss';

const DropDown = ({ title, options, value, onChange, active, divided, onClick, key }) => (
    <div className={`dropDown ${active ? 'active' : ''} ${divided ? 'divided' : ''}`} onClick={onClick}>
        {title && <p>{title}</p>}
        <select onChange={(e) => onChange(e.target.value)} value={value}>
            {options.map((opt) => (
                <option key={opt.key} value={opt.value}>
                    {opt.label}
                </option>
            ))}
        </select>
    </div>
);

export default DropDown;
