import React from 'react';
import './Select.scss';

const Select = ({ value, options, label, ...rest }) => (
    <label className="select-container">
        <b>{label}</b>
        <select value={value} {...rest}>
            {options.map((option, i) =>
                <option key={i} value={option.value}>{option.label || option.value}</option>
            )}
        </select>
    </label>
)

export default Select;