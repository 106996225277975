import React from 'react';
import './TextArea.scss';

const TextArea = ({ label, ...rest }) => (
    <div className="textarea-container">
        <b>{label}</b>
        <textarea {...rest} />
    </div>
);

export default TextArea;
