import React from 'react';
import './ProductNavigation.scss';

import Icon from './assets/sort_down.svg';

const ProductNavigation = ({ paths, currentPath, onChange }) => (
    <div className="product-naviation">
        <select value={currentPath} onChange={(e) => onChange(e.target.value)}>
            {!!paths &&
                paths.map((path, i) => (
                    <option key={i} value={path.value}>
                        {path.label}
                    </option>
                ))}
        </select>
        <img src={Icon} alt="Velja flokk" className="icon" />
    </div>
);

export default ProductNavigation;
