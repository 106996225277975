import React from 'react';
import './Button.scss';

const Button = ({ children, icon, onClick, active, style }) => (
    <div className="button-container" onClick={onClick} style={style}>
        <button className={active ? 'active' : ''}>
            {typeof icon === 'object' ? icon : <img src={icon} alt={'button'} />}
            {children}
        </button>
    </div>
)


export default Button;