import React from 'react';
import './PizzaHeader.scss';

const PizzaHeader = ({ pizza }) => (
    <div className="pizzaHeader">
        <div className="full">
            <img src={pizza} alt="" />
        </div>
    </div>
)

export default PizzaHeader;