import React from 'react';
import './CheckGroup.scss';

const CheckGroup = ({ title, options, values, toggle, secondaryToggle, secondaryLabel, valueKey }) => (
    <div className="check-group">
        <h3>{title}</h3>
        <div class="toppingsInGroup">
            {values &&
                options.map((option) => (
                    <div className="check-box">
                        {/* Checkbox */}
                        <div
                            className={`check-mark ${
                                values.find((val) => val[valueKey] === option[valueKey]) ? 'active double' : 'double'
                            }`}
                            onClick={(e) => toggle(option[valueKey])}
                        >
                            <div className={`check-mark-border`}>
                                <p>✓</p>
                            </div>
                        </div>
                        <button
                            className={
                                values.map((value) => value[valueKey]).includes(option[valueKey])
                                    ? 'active topping'
                                    : 'topping'
                            }
                            onClick={(e) => toggle(option[valueKey])}
                        >
                            {option.label}
                        </button>
                        {!!option.secondary && (
                            <button
                                className={
                                    values.find((val) => val[valueKey] === option[valueKey])?.amount === 2
                                        ? 'active double'
                                        : 'double'
                                }
                                onClick={(e) => secondaryToggle(option[valueKey])}
                            >
                                {secondaryLabel}
                            </button>
                        )}
                    </div>
                ))}
        </div>
    </div>
);

export default CheckGroup;
